import { Stack, Typography, FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { Input } from 'reactstrap';

const CustomPageSize = ({ setPageSize, pageSize }) => {
  const PageLimits = [50, 100, 200, 300, 500, 1000];
  return (
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
      <Typography
        variant="span"
        style={{
          backgroundColor: '#EEEBEB',
          fontSize: '12px',
          padding: '6px 5px 6px',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          fontWeight: '700',
          border: '1px solid #ccc',
        }}
      >
        Page Size{' '}
      </Typography>
      <FormControl size="small">
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={pageSize}
          sx={{
            ':hover': {
              border: 'none',
            },
            '.MuiSelect-icon': {
              right: 0,
              color: 'black',
            },
            '.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
              {
                paddingRight: '20px',
              },
            '.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
              padding: '4.5px 4px',
            },
          }}
          style={{
            padding: '5.5px 6px !important',
            height: '30px',
            width: 'fit-content',
            borderRadius: '5px',
            outline: '1.5px solid black',
            fontSize: '13px',
            fontWeight: '600',
            marginLeft: '-3px',
            backgroundColor: '#fff',
          }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          autoWidth
        >
          {PageLimits.map((pageSize) => (
            <MenuItem
              style={{
                fontSize: '13px',
              }}
              key={pageSize}
              value={pageSize}
            >
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default CustomPageSize;
