import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import projectLogo from '../../assets/images/logo.png';

// eslint-disable-next-line react/display-name
const Logo = forwardRef(({ disabledLink = false }) => {
  const logo = (
    <img alt="retailz pos logo" className="project-logo" src={projectLogo} style={{ width: 180, height: 20 }} />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
