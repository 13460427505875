import React from 'react';
import 'flatpickr/dist/themes/material_red.css';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import { Button } from '@mui/material';

export const DateRangerWithTime = (props) => {
  const [value, setValue] = React.useState(new Date(props?.value));
  const fp = React.useRef();
  const handleValueChange = (newValue) => {
    setValue(newValue[0]);
    props.setValue(newValue[0]);
    props?.setDateEmptyError();
  };

  const applyDate = () => {
    fp.current.flatpickr.close();
  };

  React.useEffect(() => {
    if (props.disabled) {
      setValue([null, null]);
    }
    if (props.title === 'Start Date' && document.querySelector('#appended_controls_start_date') === null) {
      document.querySelectorAll('.flatpickr-calendar')[0].insertAdjacentHTML(
        'beforeend',
        `<div class="appended_controls" id="appended_controls_start_date">
        <button id="apply-flatpickr-btn" class="start_apply_btn">Apply</button>
      </div>`
      );
      document.querySelector('.start_apply_btn').addEventListener('click', applyDate);
    } else if (props.title === 'End Date' && document.querySelector('#appended_controls_end_date') === null) {
      document.querySelectorAll('.flatpickr-calendar')[1].insertAdjacentHTML(
        'beforeend',
        `<div class="appended_controls" id="appended_controls_end_date">
        <button id="apply-flatpickr-btn" class="end_apply_btn">Apply</button>
      </div>`
      );
      document.querySelector('.end_apply_btn').addEventListener('click', applyDate);
    }
  }, [props.disabled]);

  return (
    <Flatpickr
      ref={fp}
      style={{
        width: '100%',
        fontSize: '15px',
        minHeight: '32px',
        borderRadius: '5px',
        borderWidth: '1px',
        padding: '5px',
      }}
      value={value}
      disabled={props.disabled}
      options={{
        enableTime: true,
        minTime: '00:00',
        time_24hr: false,
        saveButton: false,
        dateFormat: 'M j, Y h:i:K',
      }}
      placeholder={props.title}
      onChange={handleValueChange}
    ></Flatpickr>
  );
};
