import { FETCH_STORES_FAILURE, FETCH_STORES_REQUEST, FETCH_STORES_SUCCESS } from './type';

const initialState = {
  token: null,
  isLoading: false,
  error: '',
  stores: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stores: action.payload.stores,
        totalPage: action.payload.totalpage,
        error: '',
      };
    case FETCH_STORES_FAILURE:
      return {
        ...state,
        isLoading: false,
        stores: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
