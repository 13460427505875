import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import FormTables from '../FormTables/index.jsx';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { generateRequiredMsg } from 'src/utils/validMsgGenerator';
import { getColumnsEntire } from 'src/components/TableColumn/EntireItemColumn';
import { LoadingButton } from '@mui/lab';
import ItemTables from '../ItemTable/index.jsx';
import { fetchItemFilterServices } from 'src/services/Stores.services.js';
import { debounce } from 'lodash';
import { Typography } from '@mui/material';
import { roundNumberTwo } from 'src/utils/abbreviators.js';
import CustomPopUp from '../TransitionModal/CustomPopUp.js';

export const CustomList = (props) => {
  const {
    formDetails,
    formError,
    setFormDetails,
    setFormError,
    customListArr,
    setCustomListArr,
    emptyTableError,
    setEmptyTableError,
  } = props;

  const [itemList, setItemList] = React.useState([]);

  const [itemListChecked, setItemListChecked] = React.useState([]);
  const [openPopup, setOpenPopUp] = React.useState(false);
  const [popUpMsg, setPopUpMsg] = React.useState({
    title: 'Alert',
    subtitle: 'Are you sure you wish to Save Promotion Details.',
    confirmBox: true,
  });

  const [itemListSpinner, setItemListSpinner] = React.useState(false);

  const [itemListErr, setItemListErr] = React.useState(false);

  const [itemSearchSpinner, setItemSearchSpinner] = React.useState(true);

  const [discountObj, setDiscountObj] = React.useState({
    quantity: '',
    price: '',
    totalPrice: '',
    minPurchaseAmount: '',
    limit: '',
    percentage: '',
  });

  const [discountObjErr, setDiscountObjErr] = React.useState({
    quantity: false,
    price: false,
    totalPrice: false,
    minPurchaseAmount: false,
    limit: false,
    percentage: false,
  });

  const handleChangeDiscount = (e, prop) => {
    if (prop === 'quantity') {
      const unitPrice = roundNumberTwo(Number(discountObj?.price));
      setDiscountObj((current) => ({
        ...current,
        [prop]: e,
        totalPrice: roundNumberTwo(Number(e * unitPrice)).toString(),
      }));
      setDiscountObjErr((current) => ({
        ...current,
        [prop]: e < 0,
      }));
    } else if (prop === 'price' || prop === 'totalPrice') {
      setDiscountObjErr((current) => ({
        ...current,
        [prop]: e.target.value < 0,
      }));
      if (prop === 'price') {
        const priceRound = roundNumberTwo(e.target.value);
        if (Number(discountObj.quantity) >= 0 && Number(priceRound) >= 0) {
          const total = roundNumberTwo(Number(discountObj?.quantity) * priceRound).toString();
          setDiscountObj({
            ...discountObj,
            [prop]: priceRound,
            totalPrice: total,
          });
        }
      } else {
        const totalPriceRound = roundNumberTwo(e.target.value);
        if (Number(totalPriceRound) >= 0 && Number(discountObj.quantity) >= 0) {
          const price = roundNumberTwo(totalPriceRound / roundNumberTwo(discountObj.quantity)).toString();
          setDiscountObj({
            ...discountObj,
            [prop]: totalPriceRound,
            price: price,
          });
        }
      }
    } else if (prop === 'minPurchaseAmount') {
      setDiscountObj((current) => ({
        ...current,
        [prop]:
          e.target.value.indexOf('.') >= 0
            ? e.target.value.substr(0, e.target.value.indexOf('.')) +
              e.target.value.substr(e.target.value.indexOf('.'), 3)
            : e.target.value,
      }));
      setDiscountObjErr((current) => ({
        ...current,
        [prop]: e.target.value < 0,
      }));
    } else if (prop === 'limit') {
      setDiscountObj((current) => ({
        ...current,
        [prop]: e,
      }));
      setDiscountObjErr((current) => ({
        ...current,
        [prop]: e === '' || e % discountObj.quantity !== 0,
      }));
    } else {
      setDiscountObj((current) => ({
        ...current,
        [prop]: e.target.value,
      }));
      if (prop === 'percentage')
        setDiscountObjErr((current) => ({
          ...current,
          [prop]: e.target.value < 0 || e.target.value > 100,
        }));
      else
        setDiscountObjErr((current) => ({
          ...current,
          [prop]: e.target.value < 0,
        }));
    }
  };

  React.useEffect(() => {
    //   setFormError({
    //     itemName: false,
    //     sku: false,
    //     upc: false,
    //     size: false,
    //     pack: false,
    //     productCode: false,
    //   });
    //   setFormDetails({
    //     sku: '',
    //     itemName: '',
    //     upc: '',
    //     size: '',
    //     pack: '',
    //     productCode: '',
    //   });
    //   setEmptyTableError(false);
    //   setItemList([]);
    setItemSearchSpinner(false);
  }, []);

  const onSubmitDiscount = () => {
    const { quantity, minPurchaseAmount, limit, totalPrice, percentage, price } = discountObj;
    const arr = [];
    setItemListErr(itemListChecked.length === 0);
    if (quantity > 0 && price > 0 && itemListChecked.length > 0 && (limit === '' ? true : limit % quantity === 0)) {
      for (let i = 0; i < itemListChecked.length; i += 1) {
        // const itemObj = itemList.find((item) => Number(item.id) === itemListChecked[i]);

        const { SKU, Name, Size, Pack, Cost, RegularPrice } = itemListChecked[i];
        for (let j = 0; j < customListArr.length; j += 1) {
          const prevItem = customListArr[j];
          if (
            Number(prevItem.qty) === Number(quantity) &&
            Number(minPurchaseAmount) === Number(prevItem.min_purchase) &&
            Number(limit) === Number(prevItem.limit_qty) &&
            Number(price) === Number(prevItem.price) &&
            Number(prevItem.itemID) === Number(itemListChecked[i])
          ) {
            setPopUpMsg({
              title: 'Alert',
              subtitle: 'Same value cannot be entered.',
              confirmBox: false,
            });
            setOpenPopUp(true);
            return;
          }
        }
        arr.push({
          uuid: uuidv4(),
          sku: SKU,
          item_name: Name,
          size: Size,
          pack: Pack,
          qty: quantity,
          unit_price: price,
          min_purchase: minPurchaseAmount || null,
          limit_qty: limit || null,
          price: price,
          tablePrice: RegularPrice,
          percentage: percentage || null,
          itemID: itemListChecked[i].SKU,
          cost: Cost,
          sale: totalPrice,
        });
      }
      setCustomListArr((currentArr) => [...currentArr, ...arr]);
      setEmptyTableError(false);
      setItemListChecked([]);
      setDiscountObj({
        quantity: '',
        price: '',
        totalPrice: '',
        minPurchaseAmount: '',
        limit: '',
        percentage: '',
      });
    } else {
      setDiscountObjErr({
        ...discountObjErr,
        quantity: quantity === '' || quantity < 0,
        price: price === '' || price < 0,
        totalPrice: totalPrice === '' || totalPrice < 0,
        limit: limit === '' ? false : limit % quantity !== 0,
      });
    }
  };

  const changeHandler = (e, prop) => {
    setFormDetails((current) => ({
      ...current,
      [prop]: e.target.value,
    }));
    setItemList([]);
  };

  const itemFilter = async () => {
    setItemListSpinner(true);
    let body = {};
    const { sku, itemName, upc, size, pack, productCode } = formDetails;
    if (sku !== '') body.sku = Number(sku);
    if (itemName !== '') body.name = itemName;
    if (pack !== '') body.pack = pack;
    if (size !== '') body.size = size;
    if (productCode !== '') body.product_code = productCode;
    if (upc !== '') body.upc = upc;
    const response = await fetchItemFilterServices(body);
    setItemList(response?.data?.data || []);
    setItemListSpinner(false);
  };

  const debouncedChangeHandler = React.useMemo(() => {
    return debounce((e, prop) => changeHandler(e, prop), 600);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (
      (formDetails.sku !== '' ||
        formDetails.itemName !== '' ||
        formDetails.upc !== '' ||
        formDetails.size !== '' ||
        formDetails.pack !== '' ||
        formDetails.productCode !== '') &&
      !itemSearchSpinner
    )
      itemFilter();
  }, [
    formDetails.sku,
    formDetails.itemName,
    formDetails.upc,
    formDetails.size,
    formDetails.pack,
    formDetails.productCode,
  ]);

  return (
    <Stack direction={'column'} spacing={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} spacing={2}>
        <Stack direction={'column'} spacing={2} width={{ xs: '100%', md: '50%' }} alignItems={'flex-start'}>
          <fieldset className="add_sales_fieldSet" width={'100%'}>
            <legend className="add_sales_fieldSet_legend_outlined">Search</legend>
            {!itemSearchSpinner && (
              <Stack direction={'column'} alignItems={'flex-start'} style={{ width: '100%' }} spacing={2}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    color="primary"
                    label="Item Name"
                    size="small"
                    defaultValue={formDetails?.itemName || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => debouncedChangeHandler(e, 'itemName')}
                    error={formError.itemName}
                    helperText={formError.itemName && generateRequiredMsg('Item Name')}
                  />
                  <TextField
                    color="primary"
                    label="SKU"
                    size="small"
                    defaultValue={formDetails?.sku || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => debouncedChangeHandler(e, 'sku')}
                    error={formError.sku}
                    helperText={formError.sku && generateRequiredMsg('SKU')}
                  />
                  <TextField
                    color="primary"
                    label="UPC"
                    size="small"
                    type={'number'}
                    defaultValue={formDetails?.upc || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => debouncedChangeHandler(e, 'upc')}
                    error={formError.upc}
                    helperText={formError.upc && generateRequiredMsg('UPC')}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    color="primary"
                    label="Size"
                    size="small"
                    type={'text'}
                    defaultValue={formDetails?.size || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => debouncedChangeHandler(e, 'size')}
                    error={formError.size}
                    helperText={formError.size && generateRequiredMsg('Size')}
                  />
                  <TextField
                    color="primary"
                    label="Pack"
                    size="small"
                    type={'text'}
                    defaultValue={formDetails?.pack || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => debouncedChangeHandler(e, 'pack')}
                    error={formError.pack}
                    helperText={formError.pack && generateRequiredMsg('Pack')}
                  />
                  <TextField
                    color="primary"
                    label="Product Code"
                    size="small"
                    defaultValue={formDetails?.productCode || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => debouncedChangeHandler(e, 'productCode')}
                    error={formError.productCode}
                    helperText={formError.productCode && generateRequiredMsg('Product Code')}
                  />
                </Stack>
              </Stack>
            )}
          </fieldset>
          <Stack direction={'column'} spacing={2} width={{ xs: '100%', md: '100%' }}>
            <fieldset className="add_sales_fieldSet" width={'100%'}>
              <legend className="add_sales_fieldSet_legend_outlined">Discount Price</legend>
              <Stack direction={'row'} style={{ width: '100%' }} spacing={2} mb={2}>
                <TextField
                  color="primary"
                  label="Quantity"
                  size="small"
                  value={discountObj?.quantity || ''}
                  style={{ width: '100%' }}
                  onChange={(e) => handleChangeDiscount(Number(e.target.value.replace(/\D/g, '')), 'quantity')}
                  error={discountObjErr.quantity}
                  helperText={discountObjErr.quantity && generateRequiredMsg('Quantity')}
                />
                <TextField
                  color="primary"
                  label="Unit Price"
                  size="small"
                  value={discountObj?.price || ''}
                  type={'number'}
                  style={{ width: '100%' }}
                  onChange={(e) => handleChangeDiscount(e, 'price')}
                  error={discountObjErr.price}
                  helperText={discountObjErr.price && generateRequiredMsg('Unit Price')}
                />
                <TextField
                  color="primary"
                  label="Total Price"
                  size="small"
                  value={discountObj?.totalPrice || ''}
                  type={'number'}
                  onChange={(e) => handleChangeDiscount(e, 'totalPrice')}
                  style={{ width: '100%' }}
                />
              </Stack>
              <Stack direction={'row'} style={{ width: '100%' }} spacing={2}>
                <TextField
                  color="primary"
                  label="Min Purchase Amount"
                  size="small"
                  value={discountObj?.minPurchaseAmount || ''}
                  style={{ width: '100%' }}
                  type={'number'}
                  onChange={(e) => handleChangeDiscount(e, 'minPurchaseAmount')}
                  error={discountObjErr.minPurchaseAmount}
                  helperText={discountObjErr.minPurchaseAmount && generateRequiredMsg('Minimum Purchase Amount')}
                />
                <TextField
                  color="primary"
                  label="Limit Quantity"
                  size="small"
                  value={discountObj?.limit || ''}
                  style={{ width: '100%' }}
                  onChange={(e) => handleChangeDiscount(Number(e.target.value.replace(/\D/g, '')), 'limit')}
                  error={discountObjErr.limit}
                  helperText={'Limit Quantity Must Be Multiplier of Quantity!'}
                />
              </Stack>
              <div className="save_button_div">
                <LoadingButton
                  size="small"
                  type="button"
                  onClick={onSubmitDiscount}
                  variant="contained"
                  color="primary"
                  style={{ color: 'white', borderRadius: '3px' }}
                >
                  <AddCircleOutlineOutlinedIcon style={{ marginRight: '10px' }} /> Add
                </LoadingButton>
              </div>
            </fieldset>
          </Stack>
        </Stack>
        <Stack direction={'column'} spacing={2} width={{ xs: '100%', md: '50%' }}>
          <fieldset className="add_sales_fieldSet" width={'100%'} style={{ maxHeight: '350px', overflowY: 'scroll' }}>
            {itemList.length > 0 && (
              <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'10px'}>
                <Typography>Total Items: {itemList?.length || '0'}</Typography>
                <Typography>Total Selected Items: {itemListChecked?.length || '0'}</Typography>
              </Stack>
            )}
            <legend className="add_sales_fieldSet_legend_outlined">Item List</legend>
            <ItemTables
              events={itemList}
              isLoading={itemListSpinner}
              totalPages={1}
              unitPrice={discountObj.price}
              itemListChecked={itemListChecked}
              setItemListChecked={setItemListChecked}
            />
          </fieldset>
          {itemListErr && <FormHelperText style={{ color: 'red' }}>Please select Item from Item List!</FormHelperText>}
        </Stack>
      </Stack>
      <Stack width={'100%'}>
        <FormTables
          events={customListArr}
          isLoading={false}
          totalPage={0}
          getColumn={getColumnsEntire}
          msg={'Custom List Item'}
          setArr={setCustomListArr}
        />
        {emptyTableError && (
          <FormHelperText style={{ color: 'red' }}>Table is empty ! Please add items.</FormHelperText>
        )}
      </Stack>
      <CustomPopUp
        open={openPopup}
        handleClose={() => setOpenPopUp(false)}
        title={popUpMsg.title}
        subtitle={popUpMsg.subtitle}
      >
        <Stack direction="row" justifyContent={'end'}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#A81A31', color: '#fff', borderRadius: '3px' }}
            onClick={() => setOpenPopUp(false)}
          >
            OK
          </Button>
        </Stack>
      </CustomPopUp>
    </Stack>
  );
};
