import React, { useState } from 'react';
import { Table, Container } from 'reactstrap';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import '../../assets/css/SalesPromotion.css';
import { CircularProgress, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import CustomPopUp from '../TransitionModal/CustomPopUp';

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 50;

export default function FormTables({ events, isLoading, totalPage, getColumn, setArr, msg }) {
  const [error, setError] = React.useState(null);
  const [index, setIndex] = React.useState();
  const [openPopUpId, setOpenPopUpId] = React.useState(null);

  const [sort, setSort] = useState(null);

  const data = React.useMemo(() => events, [events]);
  const columns = React.useMemo(() => getColumn(setOpenPopUpId, msg), [index]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, setSortBy, state, setPageSize } =
    tableInstance;

  const { pageIndex, pageSize } = state;

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  const deleteRow = () => {
    setArr((items) => items.filter((item) => item.uuid !== openPopUpId));
    setOpenPopUpId(null);
  };

  return (
    <>
      <Container>
        <CustomPopUp
          title={'Alert'}
          subtitle={`You want to delete this ${msg}?`}
          open={openPopUpId}
          handleClose={() => setOpenPopUpId(null)}
        >
          <Stack direction="row" justifyContent={'end'}>
            <Button
              onClick={deleteRow}
              style={{ color: 'black', backgroundColor: '#dbd9d9', borderRadius: '3px', marginRight: '5px' }}
            >
              YES
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#A81A31', color: '#fff', borderRadius: '3px' }}
              onClick={() => setOpenPopUpId(null)}
            >
              NO
            </Button>
          </Stack>
        </CustomPopUp>
        <Card>
          <Scrollbar>
            <TableContainer>
              {isLoading ? (
                <React.Fragment>
                  <div className="no_posts">
                    <CircularProgress />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="no_posts">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table {...getTableProps()} style={{ width: '100%' }}>
                        <TableHead>
                          {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                              {headerGroup.headers.map((column) => (
                                <TableCell
                                  style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    padding: '0px 0px 0px 16px',
                                  }}
                                  key={column.id}
                                  onClick={() => {
                                    if (!column.disableSortBy) {
                                      const desc =
                                        column.isSortedDesc === true
                                          ? undefined
                                          : column.isSortedDesc === false
                                          ? true
                                          : false;
                                      setSort({
                                        sortBy: column.id,
                                        sortDirection: desc ? 'desc' : 'asc',
                                      });
                                      setSortBy([{ id: column.id, desc }]);
                                    }
                                  }}
                                  {...column.getHeaderProps()}
                                >
                                  {column.render('Header')}
                                  <span className="mx-2">
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <i className="bi bi-caret-down-fill " />
                                      ) : (
                                        <i className="bi bi-caret-up-fill " />
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <TableRow
                                {...row.getRowProps()}
                                key={row.id}
                                style={{
                                  backgroundColor:
                                    Number(row.original?.cost) > Number(row.original?.unit_price)
                                      ? '#e1000a44'
                                      : '#fff',
                                }}
                              >
                                {row.cells.map((cell) => {
                                  return (
                                    <TableCell
                                      style={{
                                        padding: '0px 0px 0px 16px',
                                      }}
                                      className="px-4 py-2"
                                      key={cell.id}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render('Cell')}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {!error && events?.length === 0 && <div className="no_posts">No Data Found !</div>}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
