import React from 'react';

export const RedDotLoader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', height: '100vh' }}>
      <svg height="100" width="100" className="blinking">
        <circle cx="50" cy="50" r="10" fill="#DC3444" />
      </svg>
    </div>
  );
};
