import { CircularProgress } from '@mui/material';
import React from 'react';

export const FullHeightLoader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <CircularProgress />
    </div>
  );
};
