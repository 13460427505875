import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import { v4 as uuidv4 } from 'uuid';
import SimpleBackdrop from '../components/Backdrop';
import { MESSAGES } from '../assets/constant/ValidationMsgs';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../assets/css/SalesPromotion.css';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { generateRequiredMsg } from 'src/utils/validMsgGenerator';
import { removeExtraSpaces } from 'src/utils/validators';
import { ItemDiscount } from 'src/components/SalesSubForms/ItemDiscount';
import { MixMatch } from 'src/components/SalesSubForms/MixMatch';
import { BoGo } from 'src/components/SalesSubForms/BoGo';
import { CustomList } from 'src/components/SalesSubForms/CustomList';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  createSalesAndPromotionsServices,
  fetchSalesAndPromotionsByIdServices,
  fetchTargetServices,
  editSalesAndPromotionsServices,
} from 'src/services/EventServices';
import { CancelOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CustomPopUp from 'src/components/TransitionModal/CustomPopUp';
import { DateRangerWithTime } from 'src/components/DateRangeWithTime';
import { SingleItemComponent } from 'src/components/SalesSubForms/SingleItem';
import { dateFormatter } from 'src/utils/formatTime';
import Tabs from 'src/components/Tabs';
import { SingleItemCombo } from 'src/components/SalesSubForms/SingleItemCombo';
import itemDiscountActive from '../assets/images/white/ic_item_discount.png';
import itemDiscountInActive from '../assets/images/black/ic_item_discount.png';
import mixAndMaxActive from '../assets/images/white/ic_mix&Match.png';
import mixAndMaxInActive from '../assets/images/black/ic_mix&Match.png';
import bogoActive from '../assets/images/white/ic_bogo.png';
import bogoInActive from '../assets/images/black/ic_bogo.png';
import singleItemActive from '../assets/images/white/ic_single_item.png';
import singleItemInActive from '../assets/images/black/ic_single_item.png';
import singleItemComboActive from '../assets/images/white/ic_single_item_combo.png';
import singleItemComboInActive from '../assets/images/black/ic_single_item_combo.png';
import customListActive from '../assets/images/white/ic_custom_list.png';
import customListInActive from '../assets/images/black/ic_custom_list.png';

export const CreateSalesPromotion = () => {
  const { id = null } = useParams();
  const [spinner, setSpinner] = useState(false);

  const [emptyTableError, setEmptyTableError] = useState(false);

  const [dateError, setDateError] = useState({
    startDate: false,
    endDate: false,
    startDateGreater: false,
  });

  const [itemsObj, setItemsObj] = useState(0);

  const [itemDiscountRadioValue, setItemDiscountRadioValue] = useState(null);

  const [itemTarget, setItemTarget] = useState('');

  const [disabledFormRadio, setDisableFormRadio] = useState(false);

  const [isSingleItem, setIsSingleItem] = useState(false);

  const [startDate, setStartDate] = useState('');

  const [endDate, setEndDate] = useState('');

  const [daysWeekArray, setDaysWeekArray] = React.useState([]);

  const [daysWeek] = React.useState([
    {
      id: 1,
      name: 'Monday',
    },
    {
      id: 2,
      name: 'Tuesday',
    },
    {
      id: 3,
      name: 'Wednesday',
    },
    {
      id: 4,
      name: 'Thursday',
    },
    {
      id: 5,
      name: 'Friday',
    },
    {
      id: 6,
      name: 'Saturday',
    },
    {
      id: 7,
      name: 'Sunday',
    },
  ]);

  const [promotionTarget] = React.useState([
    {
      id: 1,
      name: 'Department',
      value: 'department',
    },
    {
      id: 2,
      name: 'Category',
      value: 'category',
    },
    {
      id: 3,
      name: 'Sub Category',
      value: 'sub_category',
    },
    {
      id: 4,
      name: 'Item Group',
      value: 'item_group',
    },
    {
      id: 5,
      name: 'Distributor',
      value: 'distributor',
    },
    {
      id: 6,
      name: 'Item Size',
      value: 'item_size',
    },
    {
      id: 7,
      name: 'Item Pack',
      value: 'item_pack',
    },
    {
      id: 8,
      name: 'Custom List',
      value: 'custom_list',
    },
  ]);

  const [subPromotionTarget, setSubPromotionTarget] = React.useState([]);
  const [openPopup, setOpenPopUp] = React.useState(false);

  const [itemsObjArr] = React.useState(['Item Discount', 'Mix & Match', 'BOGO', 'Custom List', 'Single Item']);

  const [promotionalDetails, setPromotionDetails] = useState({
    promotionName: '',
    limitedTimePromotion: false,
    promotionTarget: '',
    target: '',
    scanDataCompany: '',
    belowCost: false,
    isDiscountOddItem: false,
  });

  const [promotionErrors, setPromotionErrors] = React.useState({
    promotionName: false,
    limitedTimePromotion: false,
    promotionTarget: false,
    target: false,
    daysWeek: false,
    scanDataCompany: false,
    belowCost: false,
  });

  const [itemDiscountObj, setItemDiscountObj] = useState({
    discount_type: '',
    qty_limit: '',
    discount_value: '',
    quantity: '',
    discount_per_unit: false,
  });

  const [itemDiscountArr, setItemDiscountArr] = useState([]);

  const [popUpMsg, setPopUpMsg] = useState({
    title: 'Alert',
    subtitle: 'Are you sure you wish to Save Promotion Details.',
    confirmBox: true,
  });

  const [itemDiscountObjError, setItemDiscountObjError] = React.useState({
    discount_type: false,
    discount_value: false,
    qty_limit: false,
    quantity: false,
    discount_per_unit: false,
  });

  const [mixAndMatchData, setMixAndMatchData] = React.useState({
    quantity1: '',
    unitPrice1: '',
    salesPrice1: '',
    discount1: 'AMOUNT',
    quantity2: '',
    discount2: 'AMOUNT',
    unitPrice2: '',
    salesPrice2: '',
    checkbox: false,
  });

  const [mixAndMatchError, setMixAndMatchError] = React.useState({
    quantity1: false,
    unitPrice1: false,
    salesPrice1: false,
    quantity2: false,
    unitPrice2: false,
    salesPrice2: false,
  });

  const [bogoItemData, setBogoItemData] = React.useState({
    scanItem: '',
    sku: '',
    itemName: '',
    rPrice: '',
    cost: '',
    buy: '',
    get: '',
    quantity: '',
    limitQuantity: '',
    discountType: '',
    discountValue: '',
  });

  const [comboItemData, setComboItemData] = React.useState({
    scanItem: '',
    sku: '',
    itemName: '',
    rPrice: '',
    cost: '',
    buy: '',
    get: '',
    getCheckbox: false,
    quantity: '',
    limitQuantity: '',
    discountType: '',
    discountValue: '',
  });

  const [bogoItemArr, setBogoItemArr] = useState([]);

  const [comboItemArr, setComboItemArr] = useState([
    {
      id: uuidv4(),
      scanItem: '',
      sku: 0,
      pack: '',
      itemSize: '',
      itemName: '',
      rPrice: 0,
      cost: 0,
    },
  ]);

  const [bogoItemError, setBogoItemError] = React.useState({
    scanItem: false,
    sku: false,
    itemName: false,
    rPrice: false,
    cost: false,
    buy: false,
    get: false,
    quantity: false,
    limitQuantity: false,
    discountType: false,
    discountValue: false,
  });

  const [comboItemError, setComboItemError] = React.useState({
    scanItem: false,
    sku: false,
    itemName: false,
    rPrice: false,
    cost: false,
    getCheckbox: false,
    buy: false,
    get: false,
    quantity: false,
    limitQuantity: false,
    discountType: false,
    discountValue: false,
    buyItem: false,
    getItem: false,
  });

  const [singleItemData, setSingleItemData] = React.useState({
    scanItem: '',
    sku: '',
    itemName: '',
    rPrice: '',
    cost: '',
    // buy: '',
    // get: '',
    quantity: '',
    limitQuantity: '',
    discountType: '',
    discountValue: '',
  });

  const [singleItemArr, setSingleItemArr] = useState([]);

  const [singleItemError, setSingleItemError] = React.useState({
    scanItem: false,
    sku: false,
    itemName: false,
    rPrice: false,
    cost: false,
    // buy: false,
    // get: false,
    quantity: false,
    limitQuantity: false,
    discountType: false,
    discountValue: false,
  });

  const [customItemObj, setCustomItemObj] = React.useState({
    sku: '',
    itemName: '',
    upc: '',
    size: '',
    pack: '',
    productCode: '',
  });

  const [customItemObjError, setCustomItemObjError] = React.useState({
    sku: false,
    itemName: false,
    upc: false,
    size: false,
    pack: false,
    productCode: false,
  });

  const [customListArr, setCustomListArr] = React.useState([]);

  const handleChange = (e, prop) => {
    if (prop === 'limitedTimePromotion') {
      setPromotionDetails((current) => ({
        ...current,
        limitedTimePromotion: e.target.checked,
      }));
      setPromotionErrors((current) => ({
        ...current,
        [prop]: e.target.checked,
      }));
    } else if (prop === 'target') {
      setPromotionDetails((current) => ({
        ...current,
        target: e,
      }));
      setPromotionErrors((current) => ({
        ...current,
        [prop]: e === '',
      }));
    } else {
      setPromotionDetails((current) => ({
        ...current,
        [prop]: e.target.value,
      }));
      setPromotionErrors((current) => ({
        ...current,
        [prop]: !e.target.value,
      }));
    }
  };

  const checkComboArr = () => {
    let flag = false;
    comboItemArr.forEach((item, index) => {
      if (item.sku === 0 || item.sku === '') {
        flag = true;
      }
    });
    return flag;
  };

  const checkForDynamicForm = (id) => {
    if (id === 0) {
      if (itemDiscountArr.length === 0) {
        setEmptyTableError(true);
        return false;
      } else {
        setEmptyTableError(false);
        return true;
      }
    } else if (id === 1) {
      const { quantity1, quantity2, salesPrice1, discount1, discount2, salesPrice2, unitPrice1, unitPrice2 } =
        mixAndMatchData;
      setMixAndMatchError({
        quantity1: quantity1 === '' || quantity1 < 0,
        unitPrice1: unitPrice1 === '' || unitPrice1 < 0,
        salesPrice1: salesPrice1 === '' || salesPrice1 < 0,
        discount1: discount1 === '',
        quantity2: quantity2 < 0,
        unitPrice2: unitPrice2 < 0,
        discount2: quantity2 > 0 ? discount2 === '' : false,
        salesPrice2: salesPrice2 < 0,
        checkbox: false,
      });
      if (quantity1 < 0 || quantity1 === '' || salesPrice1 < 0 || salesPrice1 === '') {
        return false;
      } else {
        if (quantity1 === quantity2 && salesPrice1 === salesPrice2 && discount1 === discount2) {
          setPopUpMsg({
            title: 'Alert',
            subtitle: 'Same value cannot be entered.',
            confirmBox: false,
          });
          setOpenPopUp(true);
          return false;
        }
        if (
          (discount1 === 'PERCENTAGE' ? salesPrice1 > 100 : false) ||
          (discount2 === 'PERCENTAGE' ? salesPrice2 > 100 : false)
        )
          return false;
        return true;
      }
    } else if (id === 2) {
      if (promotionalDetails.promotionTarget === '' && promotionalDetails.target === '') {
        setPromotionErrors({
          ...promotionErrors,
          promotionTarget: true,
          target: true,
        });
        return false;
      } else if (bogoItemArr.length === 0) {
        setEmptyTableError(true);
        return false;
      } else {
        setEmptyTableError(false);
        return true;
      }
    } else if (id === 4) {
      if (singleItemArr.length === 0) {
        setEmptyTableError(true);
        return false;
      } else {
        setEmptyTableError(false);
        return true;
      }
    } else if (id === 3) {
      if (customListArr.length === 0) {
        setEmptyTableError(true);
        return false;
      } else {
        setEmptyTableError(false);
        return true;
      }
    } else if (id === 5) {
      const { buy, get, quantity, limitQuantity, discountType, discountValue, getCheckbox } = comboItemData;

      setComboItemError({
        scanItem: false,
        sku: false,
        itemName: false,
        rPrice: false,
        cost: false,
        buy: isSingleItem ? false : buy < 0 || buy === '',
        get: isSingleItem ? false : get < 0 || get === '',
        limitQuantity: limitQuantity === '' ? false : limitQuantity % quantity !== 0,
        discountType: discountType < 0 || discountType === '',
        discountValue: discountType === 'FREE' ? false : discountValue < 0 || discountValue === '',
        buyItem: promotionalDetails?.promotionType !== 'items' || !comboItemData.sku,
        getItem: getCheckbox ? checkComboArr() : false,
      });
      if (discountType === 'FREE' ? false : discountValue < 0 || discountValue === '') {
        return false;
      }
      if (limitQuantity === '' ? false : limitQuantity % quantity !== 0) {
        return false;
      }
      if (comboItemData.getCheckbox && comboItemData.get !== comboItemArr.length) {
        setOpenPopUp(true);
        setPopUpMsg({
          title: 'Alert',
          subtitle: 'The No of target Get items must be equal to Get!',
          confirmBox: false,
        });
        return false;
      }
      return promotionalDetails?.promotionType === 'items' ? comboItemData.sku : promotionalDetails.target;
    } else return true;
  };

  const onSubmit = async (e) => {
    try {
      setOpenPopUp(false);
      e.preventDefault();
      const { promotionName, promotionTarget, target, belowCost, limitedTimePromotion } = promotionalDetails;
      const checkedForm = checkForDynamicForm(itemsObj);
      if (limitedTimePromotion) {
        setDateError({
          startDate: !startDate,
          endDate: !endDate,
          startDateGreater: new Date(startDate).getTime() > new Date(endDate).getTime(),
        });
        if (!startDate || !endDate) return;
        else if (startDate > endDate) {
          return;
        }
      }
      if (!limitedTimePromotion) {
        setDateError({
          startDate: false,
          endDate: false,
          startDateGreater: false,
        });
      }
      if (
        removeExtraSpaces(promotionName) !== '' &&
        (itemTarget !== '' || itemsObj === 3 ? true : promotionTarget !== '') &&
        (itemTarget !== '' || itemsObj === 3 ? true : target !== '') &&
        checkedForm
      ) {
        const startDateZ = `${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
        const endDateZ = `${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
        let itemDiscountArray = [];
        if (itemsObj === 0) {
          for (let i = 0; i < itemDiscountArr.length; i += 1) {
            const itemDiscountArrObj = itemDiscountArr[i];
            itemDiscountArray.push({
              discount_per_unit: itemDiscountArrObj.discount_per_unit.toString(),
              discount_type: itemDiscountArrObj.discount_type,
              discount_value: Number(itemDiscountArrObj.discount_value),
              limit_quantity: itemDiscountArrObj?.qty_limit || undefined,
              quantity: itemDiscountArrObj.quantity,
              promotion_type: promotionTarget.toString(),
              promotion_target: target === '' ? null : Number(target),
            });
          }
        }
        let bogoArr = [];
        if (itemsObj === 2) {
          for (let i = 0; i < bogoItemArr.length; i += 1) {
            const bogoObj = bogoItemArr[i];
            bogoArr.push({
              buy: bogoObj.bogo_buy,
              get: bogoObj.bogo_get,
              quantity: bogoObj.quantity,
              limit_quantity: Number(bogoObj.qty_limit) || undefined,
              discount_type: bogoObj.bogo_discount_type,
              discount_value: Number(bogoObj.bogo_discount_value),
              promotion_type: bogoObj.promotion_type ? bogoObj.promotion_type : promotionTarget.toString(),
              promotion_target: bogoItemData.sku ? Number(bogoItemData.sku) : Number(bogoObj.promotion_target),
            });
          }
        }
        if (itemsObj === 4) {
          for (let i = 0; i < singleItemArr.length; i += 1) {
            const singleItemObj = singleItemArr[i];
            bogoArr.push({
              buy: null,
              get: null,
              quantity: singleItemObj.quantity,
              limit_quantity: Number(singleItemObj.qty_limit) || undefined,
              discount_type: singleItemObj.bogo_discount_type,
              discount_value: Number(singleItemObj.bogo_discount_value),
              promotion_type: singleItemObj.promotion_type ? singleItemObj.promotion_type : promotionTarget.toString(),
              promotion_target: bogoItemData.sku ? Number(bogoItemData.sku) : Number(singleItemObj.promotion_target),
            });
          }
        }
        let customArrReq = [];
        if (itemsObj === 3) {
          for (let i = 0; i < customListArr.length; i += 1) {
            const customObj = customListArr[i];
            customArrReq.push({
              quantity: Number(customObj.qty),
              unit_price: Number(customObj.unit_price),
              minimum_purchase_amount: Number(customObj.min_purchase) || undefined,
              limit: Number(customObj.limit_qty) || undefined,
              discount_value: Number(customObj.sale),
              percentage: Number(customObj.percentage),
              promotion_type: 'items',
              promotion_target: Number(customObj?.itemID || customObj.promotion_target),
            });
          }
        }
        let comboArrReq = [];
        if (itemsObj === 5) {
          if (comboItemData.getCheckbox) {
            for (let i = 0; i < comboItemArr.length; i += 1) {
              const customObj = comboItemArr[i];
              comboArrReq.push({
                item_id: +customObj.sku,
                quantity: 1,
              });
            }
          }
        }

        let body = {
          promotion_name: promotionName,
          is_scan_data: itemDiscountRadioValue === null ? 'false' : (Number(itemDiscountRadioValue) === 0).toString(),
          is_coupon: itemDiscountRadioValue === null ? 'false' : (Number(itemDiscountRadioValue) === 1).toString(),
          is_retailer_funded:
            itemDiscountRadioValue === null ? 'false' : (Number(itemDiscountRadioValue) === 2).toString(),
          is_other_manufacture:
            itemDiscountRadioValue === null ? 'false' : (Number(itemDiscountRadioValue) === 3).toString(),
          is_manufacture: itemDiscountRadioValue === null ? 'false' : (Number(itemDiscountRadioValue) === 4).toString(),
          is_loyalty: itemDiscountRadioValue === null ? 'false' : (Number(itemDiscountRadioValue) === 5).toString(),
          limited_time_promotion: limitedTimePromotion.toString(),
          start_date: limitedTimePromotion ? startDateZ : null,
          end_date: limitedTimePromotion ? endDateZ : null,
          allow_below_cost_sale: belowCost.toString(),
          is_sunday: (daysWeekArray.indexOf(7) !== -1).toString(),
          is_monday: (daysWeekArray.indexOf(1) !== -1).toString(),
          is_tuesday: (daysWeekArray.indexOf(2) !== -1).toString(),
          is_wednesday: (daysWeekArray.indexOf(3) !== -1).toString(),
          is_thursday: (daysWeekArray.indexOf(4) !== -1).toString(),
          is_friday: (daysWeekArray.indexOf(5) !== -1).toString(),
          is_saturday: (daysWeekArray.indexOf(6) !== -1).toString(),
          is_item_discount: (itemsObj === 0).toString(),
          item_discount: itemsObj === 0 ? itemDiscountArray : [],
          is_mix_and_match: (itemsObj === 1).toString(),
          is_combo: (itemsObj === 5).toString(),
          is_discount_odd_item: promotionalDetails.isDiscountOddItem.toString(),
          mix_and_match: {
            // is_discount_odd_item: mixAndMatchData.checkbox.toString(),
            sales:
              itemsObj === 1
                ? [
                    {
                      quantity: Number(mixAndMatchData.quantity1),
                      discount_type: mixAndMatchData.discount1,
                      unit_price: Number(mixAndMatchData.salesPrice1),
                      promotion_type: promotionTarget.toString(),
                      promotion_target: target === '' ? null : Number(target),
                    },
                  ]
                : [],
          },
          is_single_item: (itemsObj === 4).toString(),
          is_bogo: (itemsObj === 2 ? (isSingleItem ? false : true) : false).toString(),
          bogo: itemsObj === 2 || itemsObj === 4 ? bogoArr : [],
          is_custom_list: (itemsObj === 3).toString(),
          custom_list: itemsObj === 3 ? customArrReq : [],
          single_item_combo:
            itemsObj === 5
              ? {
                  buy: comboItemData.buy,
                  get: comboItemData.get,
                  promotion_type: promotionalDetails?.promotionTarget || 'items',
                  promotion_target: +promotionalDetails?.target || +comboItemData.sku,
                  quantity: +comboItemData.quantity,
                  limit_quantity: comboItemData.limitQuantity || undefined,
                  discount_type: comboItemData.discountType,
                  discount_value: +comboItemData.discountValue,
                  target_items: comboItemData.getCheckbox ? comboArrReq?.length : 0,
                  items: comboArrReq,
                }
              : [],
        };
        if (mixAndMatchData.quantity2 > 0 && mixAndMatchData.salesPrice2 > 0) {
          body.mix_and_match.sales.push({
            quantity: Number(mixAndMatchData.quantity2),
            unit_price: Number(mixAndMatchData.salesPrice2),
            discount_type: mixAndMatchData.discount2,
            promotion_type: promotionTarget.toString(),
            promotion_target: target === '' ? null : Number(target),
          });
        }
        if (itemsObj === 1) {
          if (
            mixAndMatchData.discount1 === mixAndMatchData.discount2 &&
            mixAndMatchData.quantity1 === mixAndMatchData.quantity2 &&
            mixAndMatchData.salesPrice1 === mixAndMatchData.salesPrice2
          ) {
            setPopUpMsg({
              title: 'Alert',
              subtitle: 'Same value cannot be entered.',
              confirmBox: false,
            });
            setOpenPopUp(true);
            return;
          }
        }
        setSpinner(true);
        const response = id
          ? await editSalesAndPromotionsServices(id, body)
          : await createSalesAndPromotionsServices(body);
        if (response.status === 201 || response.status === 200) {
          window.location.href = '/';
        }
      } else {
        setPromotionErrors({
          ...promotionErrors,
          promotionName: !removeExtraSpaces(promotionName),
          promotionTarget:
            itemTarget !== '' || itemsObj === 3 || itemsObj === 5 ? false : !removeExtraSpaces(promotionTarget),
          target: itemTarget !== '' || itemsObj === 3 || itemsObj === 5 ? false : !removeExtraSpaces(target),
          daysWeek: daysWeekArray.length === 0,
        });
      }
    } catch (err) {
      setSpinner(false);
      setPopUpMsg({
        title: 'Alert',
        subtitle: err.response.data?.message || 'Error ocurred.',
        confirmBox: false,
      });
      setOpenPopUp(true);
      console.log(err);
    }
  };
  const handleDaysWeek = (id, checked) => {
    if (!checked) {
      const arr = daysWeekArray;
      const index = arr.indexOf(id);
      if (index > -1) {
        arr.splice(index, 1);
      }
      setDaysWeekArray([...arr]);
      setPromotionErrors((current) => ({
        ...current,
        daysWeek: arr.length === 0,
      }));
    } else {
      setDaysWeekArray((currentArr) => [...currentArr, id]);
      setPromotionErrors((current) => ({
        ...current,
        daysWeek: false,
      }));
    }
  };

  React.useEffect(() => {
    const fetchTargets = async () => {
      setDisableFormRadio(false);
      try {
        setSpinner(true);
        setSubPromotionTarget([]);
        // setItemTarget('');
        if (!id) {
          setPromotionDetails((current) => ({
            ...current,
            target: '',
          }));
        }
        const type = promotionTarget?.find(
          (item) => item.value === promotionalDetails?.promotionTarget?.toLowerCase()
        )?.value;
        const response = await fetchTargetServices(type);
        const { data } = response.data;
        setSubPromotionTarget(data);
        setSpinner(false);
      } catch (err) {
        setSpinner(false);
        console.log(err);
      }
    };
    if (promotionalDetails.promotionTarget !== '' && promotionalDetails.promotionTarget !== 'custom_list')
      fetchTargets();
    else if (promotionalDetails.promotionTarget === 'custom_list') {
      setDisableFormRadio(true);
      setItemsObj(3);
    } else {
      setPromotionDetails({
        ...promotionalDetails,
        target: '',
      });
      setSubPromotionTarget([]);
    }
  }, [promotionalDetails.promotionTarget]);

  React.useMemo(() => {
    setDateError({
      startDate: !startDate,
      endDate: !endDate,
      startDateGreater: new Date(startDate).getTime() > new Date(endDate).getTime(),
    });
  }, [startDate, endDate]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.Name,
    limit: 1500,
  });

  React.useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        setSpinner(true);
        const response = await fetchSalesAndPromotionsByIdServices(id);
        const { data } = response.data;
        const days = [];
        setIsSingleItem(data?.salesPromotion?.isSingleItem || false);
        if (data.salesPromotion.isMonday) days.push(1);
        if (data.salesPromotion.isTuesday) days.push(2);
        if (data.salesPromotion.isWednesday) days.push(3);
        if (data.salesPromotion.isThursday) days.push(4);
        if (data.salesPromotion.isFriday) days.push(5);
        if (data.salesPromotion.isSaturday) days.push(6);
        if (data.salesPromotion.isSunday) days.push(7);
        // setScanDataSwitch(data.salesPromotion?.isScanData || false);
        setDaysWeekArray((current) => [current, ...days]);

        if (data.salesPromotion.isItemDiscount) {
          if (data.salesPromotion.isScanData) {
            setItemDiscountRadioValue(0);
          } else if (data.salesPromotion.isCoupon) {
            setItemDiscountRadioValue(1);
          } else if (data.salesPromotion.isRetailerFunded) {
            setItemDiscountRadioValue(2);
          } else if (data.salesPromotion.isOtherManufacture) {
            setItemDiscountRadioValue(3);
          } else if (data.salesPromotion.isManufacture) {
            setItemDiscountRadioValue(4);
          } else if (data.salesPromotion.isLoyalty) {
            setItemDiscountRadioValue(5);
          } else {
            setItemDiscountRadioValue(null);
          }
          setPromotionDetails({
            promotionName: data?.salesPromotion?.promotionName || '',
            limitedTimePromotion: data?.salesPromotion?.startDate ? true : false,
            isDiscountOddItem: data.salesPromotion?.isDiscountOddItem || false,
            scanDataCompany: data.salesPromotion?.promotionName,
            belowCost: data.salesPromotion?.allowBelowCostSale,
            promotionTarget:
              data.salesPromotion.itemDiscount[0].targetType === 'items'
                ? ''
                : data.salesPromotion.itemDiscount[0].targetType.toLowerCase() || '',
            target:
              data.salesPromotion.itemDiscount[0].targetType === 'items'
                ? ''
                : Number(data.salesPromotion.itemDiscount[0].refId) || '',
          });

          let itemDiscountArray = [];
          for (let i = 0; i < data.salesPromotion.itemDiscount.length; i += 1) {
            const itemDiscountArrObj = data.salesPromotion.itemDiscount[i];
            itemDiscountArray.push({
              uuid: uuidv4(),
              discount_per_unit: itemDiscountArrObj.discountPerUnit,
              discount_type: itemDiscountArrObj.discountType,
              discount_value: itemDiscountArrObj.discountValue,
              quantity: itemDiscountArrObj.quantity,
              qty_limit: itemDiscountArrObj.limitQuantity,
              promotion_type: itemDiscountArrObj?.targetType?.toString(),
              promotion_target: itemDiscountArrObj.refId,
            });
          }
          setItemDiscountArr(itemDiscountArray);
          setItemsObj(0);
        }

        if (data.salesPromotion.isMixAndMatch) {
          setItemsObj(1);
          setPromotionDetails({
            promotionName: data?.salesPromotion?.promotionName || '',
            limitedTimePromotion: data?.salesPromotion?.startDate ? true : false,
            isDiscountOddItem: data.salesPromotion?.isDiscountOddItem || false,
            scanDataCompany: data.salesPromotion?.promotionName,
            belowCost: data.salesPromotion?.allowBelowCostSale,
            promotionTarget:
              data.salesPromotion.mixAndMatch[0].targetType === 'items'
                ? ''
                : data.salesPromotion.mixAndMatch[0].targetType || '',
            target:
              data.salesPromotion.mixAndMatch[0].targetType === 'items'
                ? ''
                : data.salesPromotion.mixAndMatch[0].refId || '',
          });
          const mAmObj1 = data.salesPromotion.mixAndMatch[0];
          const mAmlength = data.salesPromotion.mixAndMatch.length;
          const mAmObj2 = data.salesPromotion?.mixAndMatch[1];
          setMixAndMatchData({
            quantity1: mAmObj1.sales?.quantity || '',
            salesPrice1: mAmObj1.sales?.unitPrice || 0,
            discount1: mAmObj1.sales.discountType,
            quantity2: mAmlength > 1 ? mAmObj2.sales?.quantity : '',
            discount2: mAmlength > 1 ? mAmObj2.sales?.discountType : '',
            salesPrice2: mAmlength > 1 ? mAmObj2.sales?.unitPrice : 0,
            checkbox: mAmObj1?.isDiscountOddItem,
          });
        }
        if (data.salesPromotion.isCombo) {
          setPromotionDetails({
            promotionName: data?.salesPromotion?.promotionName || '',
            isDiscountOddItem: data.salesPromotion?.isDiscountOddItem || false,
            limitedTimePromotion: data?.salesPromotion?.startDate ? true : false,
            scanDataCompany: data.salesPromotion?.promotionName,
            belowCost: data.salesPromotion?.allowBelowCostSale,
            promotionTarget:
              data.salesPromotion.comboList.targetType === 'items'
                ? ''
                : data.salesPromotion.comboList.targetType || '',
            target: data.salesPromotion.comboList.refId || '',
          });

          setComboItemData({
            scanItem: '',
            sku: data.salesPromotion.comboList?.sku || '',
            itemName: data.salesPromotion.comboList?.name || '',
            rPrice: data.salesPromotion.comboList?.price || '',
            cost: data.salesPromotion.comboList?.cost || '',
            buy: data.salesPromotion.comboList?.buy || '',
            pack: data.salesPromotion.comboList?.pack || '',
            itemSize: data.salesPromotion.comboList?.size || '',
            get: data.salesPromotion.comboList?.get || '',
            getCheckbox: data.salesPromotion.comboList?.targetItems.length !== 0 ?? false,
            quantity: data.salesPromotion.comboList?.quantity || '',
            limitQuantity: data.salesPromotion.comboList?.limit || '',
            discountType: data.salesPromotion.comboList?.discountType || '',
            discountValue: data.salesPromotion.comboList?.discountValue || '',
          });

          let comboArr = [];
          for (let i = 0; i < data.salesPromotion.comboList.targetItems.length; i += 1) {
            const comboListObj = data.salesPromotion.comboList.targetItems[i];
            comboArr.push({
              id: uuidv4(),
              scanItem: '',
              sku: comboListObj?.sku || '',
              pack: comboListObj?.pack || '',
              itemSize: comboListObj?.size || '',
              itemName: comboListObj?.name || '',
              rPrice: comboListObj?.price || '',
              cost: comboListObj?.cost || '',
            });
          }
          if (data.salesPromotion.comboList.targetItems.length === 0) {
            comboArr.push({
              id: uuidv4(),
              scanItem: '',
              sku: '',
              pack: '',
              itemSize: '',
              itemName: '',
              rPrice: '',
              cost: '',
            });
          }
          setComboItemArr(comboArr);
          setItemsObj(5);
        }
        if (data.salesPromotion.isBogo || data.salesPromotion.isSingleItem) {
          setItemsObj(data.salesPromotion.isSingleItem ? 4 : 2);
          setPromotionDetails({
            promotionName: data?.salesPromotion?.promotionName || '',
            isDiscountOddItem: data.salesPromotion?.isDiscountOddItem || false,
            limitedTimePromotion: data?.salesPromotion?.startDate ? true : false,
            scanDataCompany: data.salesPromotion?.promotionName,
            belowCost: data.salesPromotion?.allowBelowCostSale,
            promotionTarget:
              data.salesPromotion.bogo[0].targetType === 'items' ? '' : data.salesPromotion.bogo[0].targetType || '',
            target: data.salesPromotion.bogo[0].refId || '',
          });
          let bogoArr = [];
          if (data.salesPromotion.isBogo) {
            setBogoItemData((current) => ({
              ...current,
              scanItem: data?.salesPromotion?.bogo[0]?.targetValue || '',
              sku: data?.salesPromotion?.bogo[0]?.refId || '',
              itemName: data?.salesPromotion?.bogo[0]?.targetValue || '',
              rPrice: data?.salesPromotion?.bogo[0]?.price || '',
              cost: '',
            }));
          }
          // else {
          // setSingleItemData((current) => ({
          //   ...current,
          //   scanItem: data?.salesPromotion?.bogo[0]?.targetValue || '',
          //   sku: data?.salesPromotion?.bogo[0]?.refId || '',
          //   itemName: data?.salesPromotion?.bogo[0]?.targetValue || '',
          //   rPrice: data?.salesPromotion?.bogo[0]?.price || '',
          //   cost: '',
          // }));
          // }
          for (let i = 0; i < data.salesPromotion.bogo.length; i += 1) {
            const bogoObj = data.salesPromotion.bogo[i];
            bogoArr.push({
              uuid: uuidv4(),
              bogo_buy: bogoObj.buy,
              bogo_get: bogoObj.get,
              sku: bogoObj.refId,
              quantity: bogoObj.quantity,
              qty_limit: Number(bogoObj.limitQuantity),
              bogo_discount_type: bogoObj.discountType,
              bogo_discount_value: Number(bogoObj.discountValue),
              promotion_type: bogoObj.targetType,
              promotion_target: bogoObj.refId,
              itemName: data.salesPromotion.isSingleItem
                ? bogoObj.targetValue
                : data.salesPromotion.bogo[0].targetValue,
              rPrice: data.salesPromotion.isSingleItem ? bogoObj.price : data.salesPromotion.bogo[0].price || 0,
              cost: data.salesPromotion.isSingleItem ? bogoObj.cost : data.salesPromotion.bogo[0].cost || 0,
            });
          }
          if (data.salesPromotion.isBogo) setBogoItemArr(bogoArr);
          else setSingleItemArr(bogoArr);
          if (data.salesPromotion.targetType === 'items') {
            setItemTarget('Items');
          }
        }
        if (data.salesPromotion.isCustomList) {
          setItemsObj(3);
          setPromotionDetails({
            promotionName: data?.salesPromotion?.promotionName || '',
            isDiscountOddItem: data.salesPromotion?.isDiscountOddItem || false,
            limitedTimePromotion: data?.salesPromotion?.startDate ? true : false,
            scanDataCompany: data.salesPromotion?.promotionName,
            belowCost: data.salesPromotion?.allowBelowCostSale,
            promotionTarget:
              data.salesPromotion.customList[0].targetType === 'items'
                ? 'custom_list'
                : data.salesPromotion.customList[0].targetType || 'custom_list',
            target:
              data.salesPromotion.customList[0].targetType === 'items'
                ? ''
                : data.salesPromotion.customList[0].refId || '',
          });
          let customArrReq = [];
          for (let i = 0; i < data.salesPromotion.customList.length; i += 1) {
            const customObj = data.salesPromotion.customList[i];
            customArrReq.push({
              uuid: uuidv4(),
              sku: customObj.refId,
              qty: Number(customObj.quantity),
              cost: Number(customObj.cost),
              tablePrice: Number(customObj.price),
              sale: Number(customObj.discountValue),
              unit_price: Number(customObj.unitPrice),
              item_name: customObj.targetValue,
              size: customObj.size,
              pack: customObj.pack,
              min_purchase: Number(customObj.minimumPurchaseAmount),
              limit_qty: Number(customObj.limit),
              percentage: Number(customObj.percentage),
              promotion_type: customObj.targetType,
              promotion_target: customObj.refId,
            });
          }
          setCustomListArr(customArrReq);
        }

        if (data.salesPromotion.targetType === 'items' || data.salesPromotion.comboList?.targetType === 'items') {
          setItemTarget('Items');
        }
        if (data.salesPromotion?.startDate) {
          setStartDate(dateFormatter(data.salesPromotion?.startDate));
          setEndDate(dateFormatter(data.salesPromotion?.endDate));
        }
        setSpinner(false);
      } catch (err) {
        setSpinner(false);
        console.log(err);
      }
    };
    if (id) {
      fetchItemDetails();
    }
  }, [id]);

  const openConfirmBox = () => {
    setPopUpMsg({
      title: 'Alert',
      subtitle: 'Are you sure you wish to Save Promotion Details.',
      confirmBox: true,
    });
    setOpenPopUp(true);
  };

  React.useEffect(() => {
    if (itemsObj === 4) {
      setPromotionDetails({
        ...promotionalDetails,
        promotionTarget: '',
        target: '',
      });
    }
  }, [itemsObj]);

  const resetForms = (val) => {
    if (val === 0) {
      setItemDiscountObj({
        discount_type: '',
        qty_limit: '',
        discount_value: '',
        quantity: '',
        discount_per_unit: false,
      });

      setItemDiscountArr([]);

      setItemDiscountObjError({
        discount_type: false,
        discount_value: false,
        qty_limit: false,
        quantity: false,
        discount_per_unit: false,
      });

      if (val === 1) {
        setMixAndMatchData({
          quantity1: '',
          unitPrice1: '',
          salesPrice1: '',
          discount1: 'AMOUNT',
          quantity2: '',
          discount2: 'AMOUNT',
          unitPrice2: '',
          salesPrice2: '',
          checkbox: false,
        });

        setMixAndMatchError({
          quantity1: false,
          unitPrice1: false,
          salesPrice1: false,
          quantity2: false,
          unitPrice2: false,
          salesPrice2: false,
        });
      }

      if (val === 2) {
        setBogoItemData({
          scanItem: '',
          sku: '',
          itemName: '',
          rPrice: '',
          cost: '',
          buy: '',
          get: '',
          quantity: '',
          limitQuantity: '',
          discountType: '',
          discountValue: '',
        });
        setBogoItemArr([]);
        setBogoItemError({
          scanItem: false,
          sku: false,
          itemName: false,
          rPrice: false,
          cost: false,
          buy: false,
          get: false,
          quantity: false,
          limitQuantity: false,
          discountType: false,
          discountValue: false,
        });
      }

      if (val === 5) {
        setComboItemData({
          scanItem: '',
          sku: '',
          itemName: '',
          rPrice: '',
          cost: '',
          buy: '',
          get: '',
          getCheckbox: false,
          quantity: '',
          limitQuantity: '',
          discountType: '',
          discountValue: '',
        });
        setComboItemArr([
          {
            id: uuidv4(),
            scanItem: '',
            sku: 0,
            pack: '',
            itemSize: '',
            itemName: '',
            rPrice: 0,
            cost: 0,
          },
        ]);
        setComboItemError({
          scanItem: false,
          sku: false,
          itemName: false,
          rPrice: false,
          cost: false,
          getCheckbox: false,
          buy: false,
          get: false,
          quantity: false,
          limitQuantity: false,
          discountType: false,
          discountValue: false,
          buyItem: false,
          getItem: false,
        });
      }

      if (val === 4) {
        setSingleItemData({
          scanItem: '',
          sku: '',
          itemName: '',
          rPrice: '',
          cost: '',
          // buy: '',
          // get: '',
          quantity: '',
          limitQuantity: '',
          discountType: '',
          discountValue: '',
        });

        setSingleItemArr([]);

        setSingleItemError({
          scanItem: false,
          sku: false,
          itemName: false,
          rPrice: false,
          cost: false,
          // buy: false,
          // get: false,
          quantity: false,
          limitQuantity: false,
          discountType: false,
          discountValue: false,
        });
      }

      if (val === 3) {
        setCustomItemObj({
          sku: '',
          itemName: '',
          upc: '',
          size: '',
          pack: '',
          productCode: '',
        });

        setCustomItemObjError({
          sku: false,
          itemName: false,
          upc: false,
          size: false,
          pack: false,
          productCode: false,
        });

        setCustomListArr([]);
      }
    }
  };

  React.useEffect(() => {
    if (comboItemData.sku) {
      setPromotionDetails({
        ...promotionalDetails,
        promotionType: 'items',
      });
    }
  }, [comboItemData.sku]);

  return (
    <Page title={`${id ? 'Edit' : 'Create'} Sales & Promotion`} goBack={true}>
      <Container style={{ width: '100%', maxWidth: '1500px' }}>
        <SimpleBackdrop open={spinner} />
        <Grid spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Card style={{ minHeight: '500px', padding: '20px' }}>
              <Stack spacing={3} style={{ width: '100%' }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems={'center'}>
                  <Stack width={{ lg: '50%', sm: '100%' }}>
                    <TextField
                      color="primary"
                      label="Promotion Name * "
                      size="small"
                      value={promotionalDetails?.promotionName || ''}
                      style={{ width: '100%' }}
                      onChange={(e) => handleChange(e, 'promotionName')}
                      error={promotionErrors.promotionName}
                      helperText={promotionErrors.promotionName && generateRequiredMsg('Promotion Name')}
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={{ lg: 'center', sm: 'flex-start' }}
                    width={{ lg: '25%', md: '70%', sm: '100%' }}
                  >
                    <FormControlLabel
                      onChange={(e) => handleChange(e, 'limitedTimePromotion')}
                      control={<Checkbox checked={promotionalDetails?.limitedTimePromotion ?? false} />}
                      label="Limited Time Promotion"
                    />
                  </Stack>
                  {!spinner && (
                    <>
                      <Stack direction={'column'} width={{ lg: '50%', md: '50%', sm: '100%' }}>
                        <DateRangerWithTime
                          title="Start Date"
                          value={startDate}
                          setDateEmptyError={() =>
                            setDateError({
                              startDate: false,
                            })
                          }
                          disabled={!promotionalDetails.limitedTimePromotion}
                          setValue={setStartDate}
                        />
                        {dateError.startDate && promotionalDetails.limitedTimePromotion && (
                          <FormHelperText style={{ color: 'red' }}>{generateRequiredMsg('Start Date')}</FormHelperText>
                        )}
                      </Stack>
                      <Stack direction={'column'} width={{ lg: '50%', md: '50%', sm: '100%' }}>
                        <DateRangerWithTime
                          title="End Date"
                          value={endDate}
                          disabled={!promotionalDetails.limitedTimePromotion}
                          setDateEmptyError={() =>
                            setDateError({
                              endDate: false,
                            })
                          }
                          setValue={setEndDate}
                        />
                        {dateError.endDate && promotionalDetails.limitedTimePromotion && (
                          <FormHelperText style={{ color: 'red' }}>{generateRequiredMsg('End Date')}</FormHelperText>
                        )}
                        {dateError.startDateGreater && (
                          <FormHelperText style={{ color: 'red' }}>{MESSAGES.ERROR_ENDTIME}</FormHelperText>
                        )}
                      </Stack>
                    </>
                  )}
                </Stack>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems={'center'}
                  spacing={2}
                  justifyContent="space-between"
                  mb={5}
                  width={'100%'}
                >
                  <Stack spacing={2} width={{ lg: '25%', xs: '100%' }}>
                    <FormControl fullWidth color="primary" error={promotionErrors.promotionTarget}>
                      <InputLabel id="demo-simple-select-label" size="small">
                        Select Promotion Target{' '}
                      </InputLabel>
                      <Select
                        disabled={singleItemData?.sku || itemsObj === 4}
                        value={promotionalDetails.promotionTarget?.toLowerCase()}
                        label="Select Promotion Target "
                        error={promotionErrors.promotionTarget}
                        onChange={(e) => handleChange(e, 'promotionTarget')}
                        size="small"
                        endAdornment={
                          promotionalDetails.promotionTarget !== '' && (
                            <InputAdornment position="end" style={{ marginRight: '20px' }}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setPromotionDetails({
                                    ...promotionalDetails,
                                    promotionTarget: '',
                                  });
                                  setDisableFormRadio(false);
                                }}
                                edge="end"
                              >
                                {<CancelOutlined />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      >
                        {promotionTarget.map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {promotionErrors.promotionTarget && (
                        <FormHelperText>{generateRequiredMsg('Promotion Target')}</FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack width={{ lg: '25%', xs: '100%' }}>
                    <Autocomplete
                      id="virtualize-demo"
                      filterOptions={filterOptions}
                      sx={{ width: '100%' }}
                      options={subPromotionTarget || []}
                      size="small"
                      disableClearable
                      disabled={
                        promotionalDetails.promotionTarget === 'custom_list' || singleItemData?.sku || itemsObj === 4
                      }
                      value={
                        promotionalDetails.target
                          ? subPromotionTarget?.find((option) => option.id == promotionalDetails?.target)
                          : { Name: '' }
                      }
                      getOptionLabel={(option) => option.Name}
                      onChange={(e, v) => handleChange(v.id, 'target')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Target"
                          placeholder="Select Target"
                          error={promotionErrors.target}
                          helperText={promotionErrors.target && generateRequiredMsg('Target')}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems="center"
                    spacing={2}
                    justifyContent="space-between"
                    width={{ lg: '25%', xs: '100%' }}
                  >
                    <FormControlLabel
                      control={<Checkbox checked={promotionalDetails.belowCost} />}
                      onChange={(e) =>
                        setPromotionDetails({
                          ...promotionalDetails,
                          belowCost: e.target.checked,
                        })
                      }
                      label="Allow Below Cost Sales for this Promotion"
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems="center"
                    spacing={2}
                    justifyContent="space-between"
                    width={{ lg: '25%', xs: '100%' }}
                  >
                    <FormControlLabel
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              value: e.target.checked,
                            },
                          },
                          'isDiscountOddItem'
                        )
                      }
                      control={<Checkbox checked={promotionalDetails.isDiscountOddItem} />}
                      label="Discount Odd Items in price"
                      disabled={itemsObj === 2}
                    />
                  </Stack>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <fieldset className="add_sales_fieldSet">
                    <legend className="add_sales_fieldSet_legend">Days of Week</legend>
                    {daysWeek.map((day) => (
                      <FormControlLabel
                        style={{ marginRight: '20px' }}
                        key={day.id}
                        onChange={(e) => handleDaysWeek(day.id, e.target.checked)}
                        control={<Checkbox checked={daysWeekArray.indexOf(day.id) !== -1} />}
                        label={day.name}
                      />
                    ))}
                  </fieldset>
                </Stack>
                <div style={{ border: '1px solid #ccc' }}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <Tabs
                      lists={[
                        {
                          id: 1,
                          title: 'Item Discount',
                          value: 0,
                          active: itemDiscountActive,
                          inactive: itemDiscountInActive,
                          disabled: false,
                        },
                        {
                          id: 2,
                          title: 'Mix & Match',
                          value: 1,
                          active: mixAndMaxActive,
                          inactive: mixAndMaxInActive,
                          disabled: false,
                        },
                        {
                          id: 3,
                          title: 'BOGO',
                          active: bogoActive,
                          inactive: bogoInActive,
                          value: 2,
                          disabled: false,
                        },
                        {
                          id: 4,
                          title: 'Single Item',
                          value: 4,
                          active: singleItemActive,
                          inactive: singleItemInActive,
                          disabled: false,
                        },
                        {
                          id: 5,
                          title: 'Single Item - Combo',
                          value: 5,
                          disabled: false,
                          active: singleItemComboActive,
                          inactive: singleItemComboInActive,
                        },
                        {
                          id: 6,
                          title: 'Custom List',
                          value: 3,
                          disabled: false,
                          active: customListActive,
                          inactive: customListInActive,
                        },
                      ]}
                      value={itemsObj}
                      setValue={(val) => {
                        resetForms(val);
                        setItemsObj(val);
                      }}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} padding={2}>
                    {/* <fieldset className="add_sales_fieldSet">
                    <legend className="add_sales_fieldSet_legend">{itemsObjArr[itemsObj]}</legend> */}
                    {itemsObj === 0 && (
                      <ItemDiscount
                        formDetails={itemDiscountObj}
                        setFormDetails={setItemDiscountObj}
                        setItemDiscountRadioValue={setItemDiscountRadioValue}
                        itemDiscountRadioValue={itemDiscountRadioValue}
                        formError={itemDiscountObjError}
                        setFormError={setItemDiscountObjError}
                        promotionTarget={promotionTarget}
                        setItemDiscountArr={setItemDiscountArr}
                        itemDiscountArr={itemDiscountArr}
                        emptyTableError={emptyTableError}
                        setEmptyTableError={setEmptyTableError}
                      />
                    )}

                    {itemsObj === 1 && (
                      <MixMatch
                        formDetails={mixAndMatchData}
                        setFormDetails={setMixAndMatchData}
                        formError={mixAndMatchError}
                        setFormError={setMixAndMatchError}
                      />
                    )}

                    {itemsObj === 2 && (
                      <BoGo
                        formDetails={bogoItemData}
                        isSingleItem={isSingleItem}
                        setIsSingleItem={setIsSingleItem}
                        setFormDetails={setBogoItemData}
                        formError={bogoItemError}
                        setFormError={setBogoItemError}
                        disabled={promotionalDetails.promotionTarget}
                        setBogoItemArr={setBogoItemArr}
                        bogoItemArr={bogoItemArr}
                        emptyTableError={emptyTableError}
                        setEmptyTableError={setEmptyTableError}
                        setItemTarget={setItemTarget}
                        itemTarget={itemTarget}
                        promotionTarget={promotionalDetails.promotionTarget}
                        target={promotionalDetails.target}
                      />
                    )}
                    {itemsObj === 5 && (
                      <SingleItemCombo
                        formDetails={comboItemData}
                        isSingleItem={isSingleItem}
                        setIsSingleItem={setIsSingleItem}
                        setFormDetails={setComboItemData}
                        formError={comboItemError}
                        setFormError={setComboItemError}
                        disabled={promotionalDetails?.promotionTarget || false}
                        setComboItemArr={setComboItemArr}
                        comboItemArr={comboItemArr}
                        emptyTableError={emptyTableError}
                        setEmptyTableError={setEmptyTableError}
                        setItemTarget={setItemTarget}
                        itemTarget={itemTarget}
                        promotionTarget={promotionalDetails.promotionTarget}
                        target={promotionalDetails.target}
                      />
                    )}
                    {itemsObj === 4 && (
                      <SingleItemComponent
                        formDetails={singleItemData}
                        isSingleItem={isSingleItem}
                        setIsSingleItem={setIsSingleItem}
                        setFormDetails={setSingleItemData}
                        formError={singleItemError}
                        setFormError={setSingleItemError}
                        disabled={promotionalDetails.promotionTarget}
                        setSingleItemArr={setSingleItemArr}
                        singleItemArr={singleItemArr}
                        emptyTableError={emptyTableError}
                        setEmptyTableError={setEmptyTableError}
                        setItemTarget={setItemTarget}
                        itemTarget={itemTarget}
                        promotionTarget={promotionalDetails.promotionTarget}
                        target={promotionalDetails.target}
                      />
                    )}
                    {itemsObj === 3 && (
                      <CustomList
                        formDetails={customItemObj}
                        setFormDetails={setCustomItemObj}
                        formError={customItemObjError}
                        setFormError={setCustomItemObjError}
                        customListArr={customListArr}
                        setCustomListArr={setCustomListArr}
                        disabled={promotionalDetails.promotionTarget}
                        emptyTableError={emptyTableError}
                        setEmptyTableError={setEmptyTableError}
                      />
                    )}
                    {/* </fieldset> */}
                  </Stack>
                </div>
              </Stack>
              <div className="save_button_div">
                <LoadingButton
                  size="small"
                  type="button"
                  onClick={openConfirmBox}
                  variant="contained"
                  color="primary"
                  style={{ color: 'white', borderRadius: '3px' }}
                  loading={spinner}
                >
                  {id ? 'Update' : 'Save'} <SaveOutlinedIcon style={{ marginLeft: '10px' }} />
                </LoadingButton>
              </div>
            </Card>
          </Grid>
          <CustomPopUp
            open={openPopup}
            handleClose={() => setOpenPopUp(false)}
            title={popUpMsg.title}
            subtitle={popUpMsg.subtitle}
          >
            {popUpMsg.confirmBox ? (
              <Stack direction="row" justifyContent={'end'}>
                <Button
                  onClick={onSubmit}
                  style={{ color: 'black', backgroundColor: '#dbd9d9', borderRadius: '3px', marginRight: '5px' }}
                >
                  YES
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#A81A31', color: '#fff', borderRadius: '3px' }}
                  onClick={() => setOpenPopUp(false)}
                >
                  NO
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" justifyContent={'end'}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#A81A31', color: '#fff', borderRadius: '3px' }}
                  onClick={() => setOpenPopUp(false)}
                >
                  OK
                </Button>
              </Stack>
            )}
          </CustomPopUp>
        </Grid>
      </Container>
    </Page>
  );
};
