import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { generateInvalidMsg, generateRequiredMsg } from 'src/utils/validMsgGenerator';
import { Checkbox } from '@mui/material';

export const MixMatch = (props) => {
  const { formDetails, formError, setFormDetails, setFormError } = props;

  const handleChange = (e, prop) => {
    if (prop === 'checkbox') {
      setFormDetails((current) => ({
        ...current,
        [prop]: e.target.checked,
      }));
    } else if (prop === 'quantity1' || prop === 'quantity2') {
      setFormDetails((current) => ({
        ...current,
        [prop]: e,
      }));
      setFormError((current) => ({
        ...current,
        [prop]: e <= 0,
      }));
    } else if (prop === 'salesPrice1' || prop === 'salesPrice2') {
      setFormDetails((current) => ({
        ...current,
        [prop]:
          e.target.value.indexOf('.') >= 0
            ? e.target.value.substr(0, e.target.value.indexOf('.')) +
              e.target.value.substr(e.target.value.indexOf('.'), 3)
            : e.target.value,
      }));
      setFormError((current) => ({
        ...current,
        [prop]: e.target.value <= 0,
      }));
    } else {
      setFormDetails((current) => ({
        ...current,
        [prop]: e.target.value,
      }));
      setFormError((current) => ({
        ...current,
        [prop]: Number(e.target.value) <= 0,
      }));
    }
  };

  // React.useEffect(() => {
  //   setFormError({
  //     quantity1: false,
  //     salesPrice1: false,
  //     discount1: false,
  //     quantity2: false,
  //     salesPrice2: false,
  //     discount2: false,
  //   });
  // }, []);

  return (
    <Stack direction={'column'} spacing={2} width={'100%'}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        width={'100%'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={2}
      >
        <Stack width={{ lg: '20%', xs: '100%' }}>
          <Typography>Sales 1 :</Typography>
        </Stack>
        <Stack width={{ lg: '30%', xs: '100%' }}>
          <TextField
            color="primary"
            label="Quantity * "
            size="small"
            value={formDetails?.quantity1 || ''}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'quantity1')}
            error={formError.quantity1}
            helperText={formError.quantity1 && generateRequiredMsg('Quantity')}
          />
        </Stack>
        <Stack width={{ lg: '30%', xs: '100%' }}>
          <FormControl color="primary" error={formError.discount1} style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label" size="small">
              Select Discount Type
            </InputLabel>
            <Select
              value={formDetails.discount1}
              label="Select Discount Type"
              size="small"
              error={formError.discount1}
              onChange={(e) => handleChange(e, 'discount1')}
            >
              <MenuItem value={'PRICE'}>Amount</MenuItem>
              <MenuItem value={'PERCENTAGE'}>Percentage</MenuItem>
            </Select>
            {formError.discount1 && <FormHelperText>{generateRequiredMsg('Discount Type')}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack width={{ lg: '30%', xs: '100%' }}>
          <TextField
            color="primary"
            label={`Sale ${formDetails?.discount1 === 'PERCENTAGE' ? 'Percentage  (%)' : 'Price ($)'} * `}
            size="small"
            type={'number'}
            value={formDetails?.salesPrice1 || ''}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(e, 'salesPrice1')}
            error={
              formError.salesPrice1 ||
              (formDetails.discount1 === 'PERCENTAGE' && formDetails.salesPrice1 !== ''
                ? Number(formDetails.salesPrice1) < 1 || Number(formDetails.salesPrice1) > 100
                : false)
            }
            helperText={
              (formError.salesPrice1 ||
                (formDetails.discount1 === 'PERCENTAGE' && formDetails.salesPrice1 !== ''
                  ? Number(formDetails.salesPrice1) < 1 || Number(formDetails.salesPrice1) > 100
                  : false)) &&
              (formDetails.salesPrice1 === '' ? generateRequiredMsg('Sale Price') : generateInvalidMsg('Sale Price'))
            }
          />
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        width={'100%'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={2}
      >
        <Stack width={{ lg: '20%', xs: '100%' }}>
          <Typography>Sales 2 :</Typography>
        </Stack>
        <Stack width={{ lg: '30%', xs: '100%' }}>
          <TextField
            color="primary"
            label="Quantity * "
            size="small"
            value={formDetails?.quantity2 || ''}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'quantity2')}
            error={formError.quantity2}
            helperText={formError.quantity2 && generateRequiredMsg('Quantity')}
          />
        </Stack>
        <Stack width={{ lg: '30%', xs: '100%' }}>
          <FormControl color="primary" error={formError.discount2} style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label" size="small">
              Select Discount Type
            </InputLabel>
            <Select
              value={formDetails.discount2}
              label="Select Discount Type"
              size="small"
              error={formError.discount2}
              onChange={(e) => handleChange(e, 'discount2')}
            >
              <MenuItem value={'PRICE'}>Amount</MenuItem>
              <MenuItem value={'PERCENTAGE'}>Percentage</MenuItem>
            </Select>
            {formError.discount2 && <FormHelperText>{generateRequiredMsg('Discount Type')}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack width={{ lg: '30%', xs: '100%' }}>
          <TextField
            color="primary"
            label={`Sale ${formDetails?.discount2 === 'PERCENTAGE' ? 'Percentage (%)' : 'Price ($)'}* `}
            size="small"
            type={'number'}
            value={formDetails?.salesPrice2 || ''}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(e, 'salesPrice2')}
            error={
              formError.salesPrice2 ||
              (formDetails.discount2 === 'PERCENTAGE' && formDetails.salesPrice2 !== ''
                ? Number(formDetails.salesPrice2) < 1 || Number(formDetails.salesPrice2) > 100
                : false)
            }
            helperText={
              (formError.salesPrice2 ||
                (formDetails.discount2 === 'PERCENTAGE' && formDetails.salesPrice2 !== ''
                  ? Number(formDetails.salesPrice2) < 1 || Number(formDetails.salesPrice2) > 100
                  : false)) &&
              (formDetails.salesPrice2 === '' ? generateRequiredMsg('Sale Price') : generateInvalidMsg('Sale Price'))
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
