import { Button, Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectStores } from 'src/redux/Stores/selector';

export const Navigator = ({ navigateTo }) => {
  const { stores } = useSelector(selectStores);
  const { id } = useParams();
  const store = stores.find((store) => store.id === id);
  const [active, setActive] = React.useState({
    dashboard: true,
    sales: false,
    setup: false,
    items: false,
  });
  React.useState(() => {
    const path = window.location.pathname;
    setActive({
      dashboard: !(path.includes('setup') || path.includes('sales') || path.includes('items')),
      sales: path.includes('sales'),
      setup: path.includes('setup'),
      items: path.includes('items'),
    });
  }, [window.location.pathname]);

  const activeBtn = {
    backgroundColor: '#A81A31',
    color: 'white',
    borderRadius: '3px',
  };
  const inactiveBtn = {
    backgroundColor: '#EEEBEB',
    color: 'black',
    borderRadius: '3px',
  };

  return (
    <Stack direction={'row'} spacing={2}>
      <Button style={active.dashboard ? activeBtn : inactiveBtn} onClick={() => navigateTo('')} variant={'contained'}>
        Dashboard
      </Button>
      <Button style={active.sales ? activeBtn : inactiveBtn} onClick={() => navigateTo('/sales')} variant={'contained'}>
        Sales Submit
      </Button>
      <Button style={active.setup ? activeBtn : inactiveBtn} onClick={() => navigateTo('/setup')} variant={'contained'}>
        Setup
      </Button>
      <Button
        style={active.items ? activeBtn : inactiveBtn}
        onClick={() => navigateTo('/items')}
        variant={'contained'}
      >{`${store?.Name} Items`}</Button>
    </Stack>
  );
};
