import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigator } from 'src/components/ScanData/Navigator';
import Autocomplete from '@mui/material/Autocomplete';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { createFilterOptions } from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Table } from 'reactstrap';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TuneIcon from '@mui/icons-material/Tune';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import '../assets/css/SalesPromotion.css';
import Scrollbar from 'src/components/scrollbar';
import { getColumns } from 'src/components/TableColumn/ItemsListing';
import TransitionsModalWithoutScroll from 'src/components/TransitionModal/TransitionModalWithoutScroll';
import { selectStores } from 'src/redux/Stores/selector';
import { useSelector } from 'react-redux';
import { fetchAgencyItemsServices } from 'src/services/Stores.services';
import { RedDotLoader } from '../components/RedDotLoader';
import { RedDot } from '../components/RedDot';
import { fetchTargetServices } from 'src/services/EventServices';
import SearchBarMui from 'src/components/Search/SearchBarMui';

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 50;

export default function ScanDataSalesItems() {
  const { stores, isLoading } = useSelector(selectStores);
  const { id } = useParams();
  const store = stores.find((store) => store.id === id);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventsData, setEventsData] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState(0);
  const [spinner, setSpinner] = React.useState(true);
  const [isLoadingSpinner, setIsLoadingSpinner] = React.useState(true);
  const [categorySpinner, setCategorySpinner] = React.useState(false);
  const [target, setTarget] = React.useState('');
  const [active, setActive] = React.useState('');
  const [type, setType] = React.useState('');
  const [subPromotionTarget, setSubPromotionTarget] = React.useState([]);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [scanDataSwitch, setScanDataSwitch] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [promotionTarget] = React.useState([
    {
      id: 1,
      name: 'Department',
      value: 'department',
    },
    {
      id: 2,
      name: 'Category',
      value: 'category',
    },
    {
      id: 3,
      name: 'Sub Category',
      value: 'sub_category',
    },
    {
      id: 4,
      name: 'Item Group',
      value: 'item_group',
    },
    {
      id: 5,
      name: 'Distributor',
      value: 'distributor',
    },
    {
      id: 6,
      name: 'Item Size',
      value: 'item_size',
    },
    {
      id: 7,
      name: 'Item Pack',
      value: 'item_pack',
    },
  ]);

  const [error, setError] = React.useState(null);
  const [index, setIndex] = React.useState();

  const [sort, setSort] = useState(null);

  React.useEffect(() => {
    const fetchAgencyItems = async () => {
      try {
        setSpinner(true);
        const response = await fetchAgencyItemsServices({ agencyId: Number(id) });
        setEventsData(response?.data?.data || []);
        setSpinner(false);
      } catch (err) {
        console.log(err);
        setSpinner(false);
      }
    };
    if (id) fetchAgencyItems();
  }, [id]);

  const data = React.useMemo(() => eventsData, [eventsData]);
  const columns = React.useMemo(() => getColumns(), [index]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    setSortBy,
    gotoPage,
    state,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };
  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  const navigateTo = (url = '') => {
    navigate(`/scandata/${id}${url}`);
  };

  const fetchAgencyItems = async (prms) => {
    try {
      setIsLoadingSpinner(true);
      const response = await fetchAgencyItemsServices({ agencyId: Number(id), ...prms });
      setEventsData(response?.data?.data || []);
      setIsLoadingSpinner(false);
    } catch (err) {
      console.log(err);
      setIsLoadingSpinner(false);
    }
  };

  const changeStatus = async (status) => {
    setFilterApplied(true);
    setOpenFilter(false);
    let params = {
      agencyId: Number(id),
    };
    params.search = globalFilter;
    if (type !== '') params.type = type;
    if (target !== '') params.refIds = [Number(target)];
    if (filterApplied) params.scanData = scanDataSwitch || false;
    fetchAgencyItems(params);
    handleClose();
  };

  const resetFilter = async () => {
    setFilterApplied(false);
    setType('');
    fetchAgencyItems({ agencyId: Number(id) });
    handleClose();
  };

  React.useMemo(() => {
    const fetchTargets = async () => {
      try {
        setCategorySpinner(true);
        setSubPromotionTarget([]);
        const typeD = promotionTarget?.find((item) => item.value === type?.toLowerCase())?.value;
        const response = await fetchTargetServices(typeD);
        const { data } = response.data;
        setSubPromotionTarget(data);
        setCategorySpinner(false);
      } catch (err) {
        setCategorySpinner(false);
        console.log(err);
      }
    };
    fetchTargets();
  }, [type]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.Name,
    limit: 1500,
  });

  React.useEffect(() => {
    setEventsData([]);
    let params = {
      agencyId: Number(id),
    };
    if (globalFilter) {
      params.search = globalFilter;
    }
    if (type !== '') params.type = type;
    if (target !== '') params.refIds = [Number(target)];
    if (filterApplied) params.scanData = scanDataSwitch || false;
    fetchAgencyItems(params);
  }, [globalFilter]);

  return (
    <>
      <Helmet>
        <title> Scan Data | Retailz POS </title>
      </Helmet>
      {isLoading ? (
        <RedDotLoader />
      ) : (
        <Container maxWidth="xl" style={{ margin: 'auto', marginTop: '-30px' }}>
          <Typography variant="h6">{store?.Name}</Typography>
          <Navigator navigateTo={navigateTo} />
          <br />
          <fieldset className="add_sales_fieldSet">
            <legend className="add_sales_fieldSet_legend">{store?.Name} Items - Listing</legend>
            <Stack
              spacing={2}
              width={{ lg: '100%', xs: '100%' }}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <SearchBarMui
                keyword={globalFilter}
                setKeyword={setGlobalFilter}
                title="SKU/UPC/Item Name"
                onSubmitHandler={onSubmitHandler}
                height={'40px'}
              />
              <div style={{ display: 'flex', justifyContent: 'space-around', marginRight: '15px' }}>
                <div
                  aria-describedby={id}
                  style={{ borderRadius: '3px', border: '1px solid black', padding: '5px 5px 0', cursor: 'pointer' }}
                  onClick={() => setOpenFilter(true)}
                >
                  <TuneIcon />
                </div>
                {filterApplied && <Button onClick={resetFilter}>Reset</Button>}
                <TransitionsModalWithoutScroll
                  open={openFilter}
                  handleClose={() => setOpenFilter(false)}
                  title={'Filters'}
                  btnFunc={() => changeStatus(active)}
                >
                  <div style={{ textAlign: 'left' }}>
                    {categorySpinner ? (
                      <Stack spacing={2} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                        <RedDot />
                      </Stack>
                    ) : (
                      <Stack spacing={2} direction={'column'}>
                        <Stack spacing={2} direction={'row'}>
                          <FormControl fullWidth color="primary">
                            <InputLabel id="demo-simple-select-label" size="small">
                              Select Promotion Target{' '}
                            </InputLabel>
                            <Select
                              value={type}
                              label="Select Promotion Target "
                              onChange={(e) => setType(e.target.value)}
                              size="small"
                              endAdornment={
                                type !== '' && (
                                  <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setType('')}
                                      edge="end"
                                    >
                                      {<CancelOutlined />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                            >
                              {promotionTarget.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Autocomplete
                            id="virtualize-demo"
                            filterOptions={filterOptions}
                            sx={{ width: '100%' }}
                            options={subPromotionTarget || []}
                            size="small"
                            disableClearable
                            value={target ? subPromotionTarget?.find((option) => option.id === target) : { Name: '' }}
                            getOptionLabel={(option) => option.Name}
                            onChange={(e, v) => setTarget(v.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Target"
                                placeholder="Select Target"
                              />
                            )}
                          />
                        </Stack>
                      </Stack>
                    )}
                  </div>
                </TransitionsModalWithoutScroll>
              </div>
            </Stack>
            <br />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                {isLoadingSpinner ? (
                  <React.Fragment>
                    <div className="no_posts">
                      <RedDot />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {error ? (
                      <React.Fragment>
                        <div className="no_posts">{error}</div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Table {...getTableProps()} style={{ width: '100%' }}>
                          <TableHead>
                            {headerGroups.map((headerGroup) => (
                              <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                                {headerGroup.headers.map((column) => (
                                  <TableCell
                                    style={{
                                      backgroundColor: 'black',
                                      color: 'white',
                                      padding: '10px 5px 10px 5px',
                                    }}
                                    key={column.id}
                                    onClick={() => {
                                      if (!column.disableSortBy) {
                                        const desc =
                                          column.isSortedDesc === true
                                            ? undefined
                                            : column.isSortedDesc === false
                                            ? true
                                            : false;
                                        setSort({
                                          sortBy: column.id,
                                          sortDirection: desc ? 'desc' : 'asc',
                                        });
                                        setSortBy([{ id: column.id, desc }]);
                                      }
                                    }}
                                    {...column.getHeaderProps()}
                                  >
                                    {column.render('Header')}
                                    <span className="mx-2">
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <i className="bi bi-caret-down-fill " />
                                        ) : (
                                          <i className="bi bi-caret-up-fill " />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableHead>
                          <TableBody {...getTableBodyProps()}>
                            {page.map((row) => {
                              prepareRow(row);
                              return (
                                <TableRow {...row.getRowProps()} key={row.id}>
                                  {row.cells.map((cell) => {
                                    return (
                                      <TableCell
                                        style={{
                                          padding: '10px 5px 10px 5px',
                                        }}
                                        className="px-4 py-2"
                                        key={cell.id}
                                        {...cell.getCellProps()}
                                      >
                                        {cell.render('Cell')}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        {!error && eventsData?.length === 0 && <div className="no_posts">No Data Found !</div>}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </TableContainer>
            </Scrollbar>
          </fieldset>
        </Container>
      )}
    </>
  );
}
