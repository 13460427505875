import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import useResponsive from '../hooks/useResponsive';
import { LoginForm } from '../sections/auth/login';
import { useDispatch } from 'react-redux';
import React from 'react';
import { fetchLoginUserThunkAction } from 'src/redux/user/action';
import { RedDotLoader } from 'src/components/RedDotLoader';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [spinner, setSpinner] = React.useState(false);
  const mdUp = useResponsive('up', 'md');

  const handleClick = () => {
    navigate('/', { replace: true });
  };

  React.useEffect(() => {
    if (window.location.search.split('token=')[1]) {
      setSpinner(true);
      dispatch(
        fetchLoginUserThunkAction(
          { email: null, password: null, token: window.location.search.split('token=')[1] },
          handleClick
        )
      );
    }
  }, [window.location.search]);

  return (
    <React.Fragment>
      <Helmet>
        <title> Retailz POS </title>
      </Helmet>

      <StyledRoot>
        {spinner ? (
          <Stack
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RedDotLoader />
          </Stack>
        ) : (
          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Sign in to Retailz POS.
              </Typography>
              <Typography variant="body2" sx={{ mb: 5 }}>
                Enter your details below.
              </Typography>
              <LoginForm />
            </StyledContent>
          </Container>
        )}
      </StyledRoot>
    </React.Fragment>
  );
}
