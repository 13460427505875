import React from 'react';
import 'flatpickr/dist/themes/material_red.css';
import Flatpickr from 'react-flatpickr';

export const DateRangerFilterV2 = (props) => {
  const [value, setValue] = React.useState(props.value);

  const handleValueChange = (newValue) => {
    props.setApply(true);
    setValue([newValue[0], newValue[1]]);
    props.setValue([newValue[0], newValue[1]]);
    props?.setDateEmptyError();
  };

  React.useEffect(() => {
    if (!props.apply) {
      setValue([null, null]);
    }
  }, [props.apply]);

  return (
    <Flatpickr
      style={{
        width: '100%',
        fontSize: '15px',
        minHeight: '32px',
        borderRadius: '5px',
        borderWidth: '1px',
        borderColor: '#ccc',
        outline: 'none',
        minWidth: props?.minWidth || '220px',
        padding: '5px',
      }}
      value={value}
      options={{
        mode: 'range',
        dateFormat: 'M d, Y',
      }}
      placeholder="Date Range"
      onChange={handleValueChange}
    />
  );
};
