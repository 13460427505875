import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { generateInvalidMsg, generateRequiredMsg } from 'src/utils/validMsgGenerator';
import '../../assets/css/bogo.css';
import { getColumns } from 'src/components/TableColumn/BogoModalColumn';
import { Button, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import FormTables from '../FormTables';
import { getColumnsDiscount } from '../TableColumn/ItemDiscountColumn';
import { LoadingButton } from '@mui/lab';
import { fetchItemSearchServices } from 'src/services/Stores.services';
import { BogoModalTable } from '../BogoModalTable';
import XLTransitionsModal from '../XLTransitionModal';
import CustomPopUp from '../TransitionModal/CustomPopUp';
import { getColumnsSingleItem } from '../TableColumn/SingleItemColumn';

export const SingleItemComponent = (props) => {
  const {
    formDetails,
    formError,
    setFormDetails,
    disabled,
    setFormError,
    singleItemArr,
    isSingleItem,
    setIsSingleItem,
    setSingleItemArr,
    emptyTableError,
    setEmptyTableError,
    setItemTarget,
    itemTarget,
    promotionTarget,
    target,
  } = props;

  const [percentageInvalid, setPercentageInValid] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [spinner, setSpinner] = React.useState(true);
  const [openModalList, setOpenModalList] = React.useState([]);
  const [openPopup, setOpenPopUp] = React.useState(false);
  const [popUpMsg, setPopUpMsg] = React.useState({
    title: 'Alert',
    subtitle: 'Are you sure you wish to Save Promotion Details.',
    confirmBox: true,
  });

  const handleChange = (e, prop) => {
    if (prop === 'quantity' || prop === 'limitQuantity') {
      setFormDetails((current) => ({
        ...current,
        [prop]: e,
      }));
    } else if (prop === 'discountValue') {
      setFormDetails((current) => ({
        ...current,
        [prop]:
          e.target.value.indexOf('.') >= 0
            ? e.target.value.substr(0, e.target.value.indexOf('.')) +
              e.target.value.substr(e.target.value.indexOf('.'), 3)
            : e.target.value,
      }));
    } else
      setFormDetails((current) => ({
        ...current,
        [prop]: e.target.value,
      }));

    if (prop === 'discountType')
      setFormError((current) => ({
        ...current,
        [prop]: e.target.value === '',
      }));
    else if (prop === 'quantity') {
      setFormError((current) => ({
        ...current,
        [prop]: e === '' || e < 0,
      }));
    } else if (prop === 'limitQuantity')
      setFormError((current) => ({
        ...current,
        [prop]: e === '' || e % formDetails.quantity !== 0,
      }));
    else
      setFormError((current) => ({
        ...current,
        [prop]: e.target.value < 0,
      }));
  };

  React.useEffect(() => {
    setEmptyTableError(false);
    setFormError({
      scanItem: false,
      sku: false,
      itemName: false,
      rPrice: false,
      cost: false,
      quantity: false,
      limitQuantity: false,
      discountType: false,
      discountValue: false,
    });
    setFormDetails({
      scanItem: '',
      sku: '',
      itemName: '',
      rPrice: '',
      cost: '',
      quantity: '',
      limitQuantity: '',
      discountType: '',
      discountValue: '',
    });
  }, []);

  const onSubmitDiscount = () => {
    const { quantity, limitQuantity, discountType, discountValue } = formDetails;
    if (
      (discountType === 'FREE' ? false : discountValue < 0 || discountValue === '') ||
      discountType === '' ||
      quantity === '' ||
      quantity === 0 ||
      (limitQuantity === '' ? false : limitQuantity % quantity !== 0)
    ) {
      setFormError({
        scanItem: false,
        sku: false,
        itemName: false,
        rPrice: false,
        cost: false,
        quantity: quantity !== 0,
        limitQuantity: limitQuantity === '' ? false : limitQuantity % quantity !== 0,
        discountType: discountType < 0 || discountType === '',
        discountValue: discountType === 'FREE' ? false : discountValue < 0 || discountValue === '',
      });
    } else if (singleItemArr.length > 0 && singleItemArr[0].bogo_discount_type !== discountType) {
      setPopUpMsg({
        title: 'Alert',
        subtitle: 'Different value for discount type cannot be entered.',
        confirmBox: false,
      });
      setOpenPopUp(true);
      return;
    } else if (itemTarget !== '' ? true : promotionTarget !== '') {
      for (let i = 0; i < singleItemArr.length; i += 1) {
        const item = singleItemArr[i];
        if (
          item.quantity === Number(quantity) &&
          item.qty_limit === limitQuantity &&
          item.bogo_discount_type === discountType &&
          item.bogo_discount_value === discountValue
        ) {
          setPopUpMsg({
            title: 'Alert',
            subtitle: 'Same value cannot be entered.',
            confirmBox: false,
          });
          setOpenPopUp(true);
          return;
        }
        if (discountType === 'PERCENTAGE') {
          setPercentageInValid(discountValue < 1 || discountValue > 100);
          if (discountValue < 1 || discountValue > 100) return;
        }
      }
      setSingleItemArr((currentItem) => [
        ...currentItem,
        {
          uuid: uuidv4(),
          quantity: Number(quantity),
          qty_limit: limitQuantity,
          bogo_discount_type: discountType,
          bogo_discount_value: discountValue,
          promotion_type: itemTarget ? 'items' : promotionTarget,
          promotion_target: itemTarget === '' ? target : Number(itemTarget),
          sku: formDetails.sku,
          itemName: formDetails.itemName,
          rPrice: formDetails.rPrice,
          cost: formDetails.cost,
        },
      ]);
      setEmptyTableError(false);
      setFormError({
        scanItem: false,
        sku: false,
        itemName: false,
        rPrice: false,
        cost: false,
        quantity: false,
        limitQuantity: false,
        discountType: false,
        discountValue: false,
      });
      setFormDetails({
        ...formDetails,
        quantity: '',
        limitQuantity: '',
        discountValue: '',
      });
    } else {
      setPopUpMsg({
        title: 'Alert',
        subtitle: 'Please select an Item to create a promotion..',
        confirmBox: false,
      });
      setOpenPopUp(true);
    }
  };

  const itemSearch = async (value) => {
    try {
      setSpinner(true);
      const response = await fetchItemSearchServices(value);
      setOpenModalList(response?.data?.data || []);
      setSpinner(false);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  React.useEffect(() => {
    if (openModal) itemSearch('');
  }, [openModal]);

  const openSearchModal = () => {
    setOpenModal(true);
  };

  const updateItemDetails = (item) => {
    setFormDetails((current) => ({
      ...current,
      scanItem: '',
      sku: item?.SKU || 0,
      itemName: item.Name,
      rPrice: item?.RegularPrice || 0,
      cost: item?.Cost || 0,
    }));
    setOpenModal(false);
    setItemTarget(item.id);
  };

  React.useMemo(() => {
    setFormDetails((current) => ({
      ...current,
      scanItem: '',
      sku: '',
      itemName: '',
      rPrice: '',
      cost: '',
    }));
  }, [disabled]);

  React.useEffect(() => {
    if (singleItemArr.length > 0) {
      setFormDetails({
        ...formDetails,
        discountType: singleItemArr[singleItemArr.length - 1].bogo_discount_type,
        sku: singleItemArr[singleItemArr.length - 1].promotion_target,
        itemName: singleItemArr[singleItemArr.length - 1].itemName,
        rPrice: singleItemArr[singleItemArr.length - 1].rPrice,
        cost: singleItemArr[singleItemArr.length - 1].cost,
      });
      setItemTarget(singleItemArr[singleItemArr.length - 1].promotion_target);
    }
  }, [singleItemArr]);

  React.useEffect(() => {
    setFormDetails({
      ...formDetails,
      quantity: '',
      discountType: '',
      discountValue: '',
    });
  }, [isSingleItem]);

  return (
    <Stack direction={'column'} spacing={2} width={'100%'}>
      <fieldset className="add_sales_fieldSet">
        <legend className="add_sales_fieldSet_legend_outlined">Single Item Formula</legend>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          width={'100%'}
          alignItems={'baseline'}
          spacing={2}
          justifyContent={'space-between'}
        >
          <Stack spacing={2} direction={'row'} width={{ xs: '100%', md: '25%' }} alignItems={'baseline'}>
            {formDetails.sku ? (
              <Button
                onClick={() =>
                  setFormDetails((current) => ({
                    ...current,
                    scanItem: '',
                    sku: '',
                    itemName: '',
                    rPrice: '',
                    cost: '',
                  }))
                }
                style={{ width: '100%', borderRadius: '3px' }}
                variant="contained"
              >
                Remove
              </Button>
            ) : (
              <Button
                disabled={disabled}
                onClick={openSearchModal}
                style={{ width: '100%', borderRadius: '3px' }}
                variant="contained"
              >
                Search
              </Button>
            )}
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            spacing={2}
            width={{ xs: '100%', md: '75%' }}
          >
            <TextField
              color="primary"
              label="SKU"
              size="small"
              disabled={true}
              value={formDetails?.sku || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'sku')}
            />
            <TextField
              color="primary"
              label="Item Name "
              size="small"
              disabled={true}
              value={formDetails?.itemName || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'itemName')}
            />
            <TextField
              color="primary"
              label="Regular Price ($)"
              size="small"
              disabled={true}
              value={formDetails?.rPrice || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'rPrice')}
            />
            <TextField
              color="primary"
              label="Cost ($) "
              size="small"
              disabled={true}
              value={formDetails?.cost || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'cost')}
            />
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          style={{ border: '0.5px solid #ccc', marginTop: '15px', borderRadius: '8px' }}
          alignItems={'center'}
          spacing={2}
          p={1}
        >
          <Stack direction={'row'} spacing={2} p={1} width={{ xs: '100%', md: '100%' }}>
            <FormControl color="primary" error={formError.discountType} style={{ width: '100%' }}>
              <InputLabel id="demo-multiple-checkbox-label" size="small">
                Select Discount Type
              </InputLabel>
              <Select
                value={formDetails.discountType}
                label="Select Discount Type"
                size="small"
                // disabled={singleItemArr.length > 0}
                error={formError.discountType}
                onChange={(e) => handleChange(e, 'discountType')}
              >
                {/* {!isSingleItem && <MenuItem value={'FREE'}>Free</MenuItem>} */}
                <MenuItem value={'AMOUNTOFF'}>Amount Off</MenuItem>
                <MenuItem value={'PRICE'}>Amount</MenuItem>
                <MenuItem value={'PERCENTAGE'}>Percentage</MenuItem>
              </Select>
              {formError.discountType && <FormHelperText>{generateRequiredMsg('Discount Type')}</FormHelperText>}
            </FormControl>
            <TextField
              color="primary"
              type={'number'}
              label="Discount Value"
              disabled={formDetails.discountType === 'FREE'}
              size="small"
              value={formDetails?.discountValue || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'discountValue')}
              error={
                formError.discountValue ||
                (formDetails.discountType === 'PERCENTAGE' && formDetails.discountValue !== ''
                  ? Number(formDetails.discountValue) < 1 || Number(formDetails.discountValue) > 100
                  : false)
              }
              helperText={
                (formError.discountValue ||
                  (formDetails.discountType === 'PERCENTAGE' && formDetails.discountValue !== ''
                    ? Number(formDetails.discountValue) < 1 || Number(formDetails.discountValue) > 100
                    : false)) &&
                (formDetails.discountValue === ''
                  ? generateRequiredMsg('Discount Value')
                  : generateInvalidMsg('Discount Value'))
              }
            />
            <TextField
              color="primary"
              label="Quantity"
              size="small"
              value={formDetails.quantity || ''}
              // disabled={!isSingleItem}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'quantity')}
              error={formError.quantity}
              helperText={formError.quantity && generateRequiredMsg('Quantity')}
            />
            <TextField
              color="primary"
              label="Limit Quantity"
              disabled={formDetails.discountType === 'FREE'}
              size="small"
              value={formDetails?.limitQuantity || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'limitQuantity')}
              error={formError.limitQuantity}
              helperText={'Limit Quantity Must Be Multiplier of Quantity!'}
            />
          </Stack>
        </Stack>
        <div className="save_button_div">
          <LoadingButton
            size="small"
            type="button"
            onClick={onSubmitDiscount}
            variant="contained"
            color="primary"
            style={{ color: 'white', borderRadius: '3px' }}
          >
            <AddCircleOutlineOutlinedIcon style={{ marginRight: '10px' }} /> Add
          </LoadingButton>
        </div>
      </fieldset>
      <Stack width={'100%'}>
        <FormTables
          events={singleItemArr}
          msg={'Single Item'}
          isLoading={false}
          totalPage={1}
          getColumn={getColumnsSingleItem}
          setArr={setSingleItemArr}
        />
        {emptyTableError && (
          <FormHelperText style={{ color: 'red' }}>Table is empty ! Please add items.</FormHelperText>
        )}
      </Stack>
      <XLTransitionsModal open={openModal} handleClose={() => setOpenModal(false)} title={'Single Item Search'}>
        <div style={{ textAlign: 'center', maxHeight: '60vh', overflow: 'scroll' }}>
          <BogoModalTable
            events={openModalList}
            getColumn={getColumns}
            isLoading={spinner}
            totalPage={1}
            setArr={updateItemDetails}
            fetch={itemSearch}
            key={1}
          />
        </div>
      </XLTransitionsModal>
      <CustomPopUp
        open={openPopup}
        title={popUpMsg.title}
        subtitle={popUpMsg.subtitle}
        handleClose={() => setOpenPopUp(false)}
      >
        <Stack direction="row" justifyContent={'end'}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#A81A31', color: '#fff', borderRadius: '3px' }}
            onClick={() => setOpenPopUp(false)}
          >
            OK
          </Button>
        </Stack>
      </CustomPopUp>
    </Stack>
  );
};
