import toast from 'react-hot-toast';
import { message } from 'src/assets/constant/messages';
import { changePasswordUserApi, getLoggedInUserApi, getUsersListApi } from '../../services/UserServices';
import {
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  REFRESH_STATE,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  RESET_IS_SIGNING,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
} from './type';

export const setLoggedInUser = ({ user, token }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token },
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER,
  };
};
export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING,
  };
};
export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING,
  };
};

export const refreshState = ({ token, user }) => ({
  type: REFRESH_STATE,
  payload: { token, user },
});

export const resetForgetpasswordUserEmail = () => {
  return {
    type: RESET_FORGET_PASSWORD_USER_EMAIL,
  };
};
export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};

export const fetchUsersSuccess = (users, totalpage) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: { users, totalpage },
  };
};

export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error,
  };
};

export const fetchLoginUserThunkAction = ({ email, password, token }, onSuccess) => {
  return async (dispatch) => {
    try {
      const promise = getLoggedInUserApi({ email: email, password, token: token });

      dispatch(setIsSigning());
      toast.promise(promise, {
        loading: message.auth.LOADING,
        success: message.auth.LOGIN_SUCCESS,
        error: (error) => `${error?.response?.data?.message || error?.message}`,
      });

      const { data } = await promise;
      if (!data?.data?.accessToken) {
        window.location.href = '/page-not-found';
      }
      localStorage.setItem('access_token', data?.data.accessToken);
      localStorage.setItem('name', data?.data.name);
      localStorage.setItem('email', data?.data.email);
      dispatch(
        setLoggedInUser({
          token: data?.data.accessToken,
          name: data?.data.name,
          email: data?.data.email,
        })
      );
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      window.location.href = '/page-not-found';
      console.log(error.response?.data?.message || error.message);
    }
  };
};

export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem('access_token');
      dispatch(setLoggedOutUser());
      localStorage.removeItem('user');
      window.location.reload();

      onSuccess();
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePasswordThunkAction = async ({ password }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await changePasswordUserApi({ password });
      toast.success(message.auth.PASSWORD_CHANGE_SUCCESS);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
};
export const fetchUsersthunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUsersRequest());
      const { data } = await getUsersListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchUsersSuccess(data?.data?.userList, data?.data?.total / params.limit, data?.data?.total));

      onSuccess();
    } catch (error) {
      dispatch(fetchUsersFailure(error.message));
      onError(error?.response?.data?.message || error.message);
    }
  };
};
