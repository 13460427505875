import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import './modal.css';
import { RedDot } from '../RedDot';
const style = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  width: window.innerWidth - 50,
  height: 'fit-content',
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 0,
  borderRadius: '5px',
  maxWidth: '500px',
  maxHeight: '90vh',
};

export default function TransitionsModalWithoutScroll({
  children,
  open,
  handleClose,
  title = '',
  btnFunc,
  btnTitle,
  resetBtn,
  resetFunc,
  loader,
  hideBtn,
}) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open} style={{ transformOrigin: 'center' }}>
          <Box sx={style}>
            <div className="modal_close_with_title" onClick={() => handleClose()}>
              <h3>{title}</h3>
              <div className="modal_close_svg">
                <CloseIcon />
              </div>
            </div>
            <div style={{ borderBottom: '0.5px solid #ccc', width: '100%', marginBottom: '15px' }} />
            {loader ? (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <RedDot />
              </div>
            ) : (
              <>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '15px', paddingBottom: '15px' }}>
                  {children}
                </div>
                {!hideBtn && (
                  <>
                    {' '}
                    <div style={{ borderBottom: '0.5px solid #ccc', width: '100%', marginBottom: '15px' }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {resetBtn && (
                        <div className="modal_btn" onClick={resetFunc}>
                          Reset
                        </div>
                      )}
                      <div className="modal_btn" onClick={btnFunc}>
                        Submit
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Box>
        </Zoom>
      </Modal>
    </div>
  );
}
