import moment from 'moment';

export const getColumns = () => {
  return [
    {
      Header: 'SKU',
      accessor: 'sku',
      disableSortBy: false,
      Cell: ({ row }) => {
        return row?.original?.SKU;
      },
    },
    {
      Header: 'UPC',
      accessor: 'upc',
      disableSortBy: false,
      Cell: ({ row }) => {
        return row?.original?.UPC;
      },
    },
    {
      Header: 'Item Name',
      accessor: 'item_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.Name;
      },
    },
    {
      Header: 'Pack Name',
      accessor: 'pack_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.Pack;
      },
    },
    {
      Header: 'Category Name',
      accessor: 'category_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.CategoryName;
      },
    },
  ];
};
