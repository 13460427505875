import { combineReducers } from 'redux';

import userReducer from './user/reducer';
import eventsReducer from './Event/reducer';
import salesSubmitReducer from './SalesSubmit/reducer';
import storesReducer from './Stores/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  events: eventsReducer,
  stores: storesReducer,
  salesSubmit: salesSubmitReducer,
});

export default rootReducer;
