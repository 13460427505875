import { Pagination, Stack, PaginationItem } from '@mui/material';
import React from 'react';
import CustomPage from '../CustomPage/CustomPage';
import CustomPageSize from '../PageSize/PageSize';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export const TablesPaginator = ({
  pageSize,
  pageCount,
  pageIndex,
  gotoPage,
  setPageSize,
  nextPage,
  display = true,
  children,
}) => {
  const handleChange = (event, value) => {
    gotoPage(value - 1);
  };
  return (
    <Stack direction={'row'} spacing={2} justifyContent={'space-between'} ml={0} mr={0} mt={0}>
      <CustomPageSize setPageSize={setPageSize} pageSize={pageSize} />
      {children}
      {display && (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Pagination
            sx={{
              '.Mui-selected': {
                borderColor: '#A81A31',
              },
              '.MuiPaginationItem-root': {
                borderRadius: '4px',
                margin: '0',
              },
            }}
            variant="outlined"
            count={pageCount}
            page={pageIndex}
            onChange={handleChange}
            boundaryCount={1}
            siblingCount={-1}
            renderItem={(item) => (
              <PaginationItem
                style={{ color: '#A81A31' }}
                slots={{ previous: KeyboardDoubleArrowLeftIcon, next: KeyboardDoubleArrowRightIcon }}
                {...item}
              />
            )}
          />
          <CustomPage pageCount={pageCount} goToNext={gotoPage} pageIndex={pageIndex} />
        </Stack>
      )}
    </Stack>
  );
};
