import React, { useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CardFooter, Table, Container, Col, Row, CardHeader, Button } from 'reactstrap';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import '../../assets/css/SalesPromotion.css';
import { CircularProgress, TableBody, TableCell, TableHead, TableRow, TableContainer, Stack } from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import SearchBarMui from '../Search/SearchBarMui';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { RedDot } from '../RedDot';

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 50;

export const BogoModalTable = ({ events, isLoading, totalPage, getColumn, setArr, fetch }) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [index, setIndex] = React.useState();

  const [sort, setSort] = useState(null);

  const data = React.useMemo(() => events, [events]);
  const columns = React.useMemo(() => getColumn(setArr), [index]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    setSortBy,
    gotoPage,
    state,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };
  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    fetch(globalFilter || '');
  }, [globalFilter]);

  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <CardHeader>
          <Row>
            <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} pr={0} pb={1}>
              <SearchBarMui
                disabled={isLoading}
                keyword={globalFilter}
                setKeyword={setGlobalFilter}
                title="Item Name"
                onSubmitHandler={onSubmitHandler}
              />
            </Stack>
          </Row>
        </CardHeader>
        {isLoading ? (
          <React.Fragment>
            <div className="no_posts">
              <RedDot />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div className="no_posts">{error}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Table {...getTableProps()} style={{ width: '100%' }}>
                  <TableHead>
                    {headerGroups.map((headerGroup) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                        {headerGroup.headers.map((column) => (
                          <TableCell
                            style={{
                              backgroundColor: 'black',
                              color: 'white',
                              padding: '0px 0px 0px 16px',
                            }}
                            key={column.id}
                            onClick={() => {
                              if (!column.disableSortBy) {
                                const desc =
                                  column.isSortedDesc === true
                                    ? undefined
                                    : column.isSortedDesc === false
                                    ? true
                                    : false;
                                setSort({
                                  sortBy: column.id,
                                  sortDirection: desc ? 'desc' : 'asc',
                                });
                                setSortBy([{ id: column.id, desc }]);
                              }
                            }}
                            {...column.getHeaderProps()}
                          >
                            {column.render('Header')}
                            <span className="mx-2">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="bi bi-caret-down-fill " />
                                ) : (
                                  <i className="bi bi-caret-up-fill " />
                                )
                              ) : (
                                ''
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <TableRow
                          {...row.getRowProps()}
                          key={row.id}
                          onClick={() => setArr(row.original)}
                          style={{ cursor: 'pointer' }}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <TableCell
                                style={{
                                  padding: '0px 0px 0px 16px',
                                }}
                                className="px-4 py-2"
                                key={cell.id}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!error && events?.length === 0 && <div className="no_posts">No Data Found !</div>}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </TableContainer>
    </Scrollbar>
  );
};
