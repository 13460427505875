import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'yyyy-MM-dd hh:mm p');
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function convertYMDbyHyphen(str) {
  const date = new Date(str);
  const mnth = '0'.concat(date.getMonth() + 1).slice(-2);
  const day = '0'.concat(date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
}

export const dateFormatter = (value) => {
  const date = value.split('T')[0];
  const time = value.split('T')[1];
  return `${date} ${time.split('.')[0]}`;
};
