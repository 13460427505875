import moment from 'moment';

export const getColumns = (timezoneOffset = '+0') => {
  return [
    {
      Header: 'Report Date Range',
      accessor: 'Report_Date_Range',
      disableSortBy: true,
      Cell: ({ row }) => {
        return `${moment(row?.original?.ReportStartDate)
          .utcOffset(timezoneOffset, false)
          .format('MMM D, YYYY')} to ${moment(row?.original?.ReportEndDate)
          .utcOffset(timezoneOffset, false)
          .format('MMM D, YYYY')}`;
      },
    },
    {
      Header: 'Submitted Date',
      accessor: 'Submitted_Date',
      disableSortBy: true,
      Cell: ({ row }) => {
        return moment(row?.original?.ReportSubmitDate).utcOffset(timezoneOffset, false).format('MMM D, YYYY') || '';
      },
    },
    {
      Header: 'Submission Type',
      accessor: 'Submission_type',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.SubmissionType || '';
      },
      Footer: (info) => {
        return <span style={{ fontSize: '14px', color: '#000', fontWeight: '600' }}>Total</span>;
      },
    },
    {
      Header: 'Total Items',
      accessor: 'Total_Items',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.TotalItems || 0;
      },
      Footer: (info) => {
        const total = info.rows.reduce((sum, row) => row?.original?.TotalItems + sum, 0);
        return <span style={{ fontSize: '14px', color: '#A81A31', fontWeight: '600' }}>${total} </span>;
      },
    },
    {
      Header: 'Total Qty',
      accessor: 'Total_Qty',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.TotalQty || 0;
      },
      Footer: (info) => {
        const total = info.rows.reduce((sum, row) => row?.original?.TotalQty + sum, 0);
        return <span style={{ fontSize: '14px', color: '#A81A31', fontWeight: '600' }}>${total}</span>;
      },
    },
    {
      Header: 'Promo Price',
      accessor: 'Promo_Price',
      disableSortBy: true,
      Cell: ({ row }) => {
        return `$${Number(`${row?.original?.PromoPrice || 0}`).toFixed(2)}`;
      },
      Footer: (info) => {
        const total = info.rows.reduce((sum, row) => parseFloat(row?.original?.PromoPrice) + sum, 0);
        return <span style={{ fontSize: '14px', color: '#A81A31', fontWeight: '600' }}>${total.toFixed(2)}</span>;
      },
    },
    {
      Header: 'Total Sales Price',
      accessor: 'Total_Sales_Price',
      disableSortBy: true,
      Cell: ({ row }) => {},
      Footer: (info) => {
        const total = info.rows.reduce((sum, row) => parseFloat(row.original.TotalSalesPrice) + sum, 0);
        return <span style={{ fontSize: '14px', color: '#A81A31', fontWeight: '600' }}>${total.toFixed(2)} </span>;
      },
    },
  ];
};
