import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import Login from './pages/LoginPage';
import NotFound from './pages/Page404';
import DashboardAppPage from './pages/SalesAndPromotionListing';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import { CreateSalesPromotion } from './pages/AddSalesPromotion';
import ScanDataDashboard from './pages/ScanDataDashboard';
import ScanDataSalesSubmit from './pages/ScanDataSalesSubmit';
import ScanDataSetup from './pages/ScanDataSetup';
import { useSelector } from 'react-redux';
import ScanDataSalesItems from './pages/ScanDataItems';
import { selectStores } from 'src/redux/Stores/selector';
import DashboardAppPageListing from './pages/ScanAndPromotionListingDraggable';

export default function Router() {
  const authenticated = window.localStorage.getItem('access_token');
  const hasURLToken = window.location.search.split('token=')[1] || null;
  const { stores } = useSelector(selectStores);

  const routes = useRoutes([
    {
      path: '/',
      element: authenticated ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { path: '/', element: <DashboardAppPageListing /> },
        { path: '/sales-and-promotion/create', element: <CreateSalesPromotion /> },
        { path: '/sales-and-promotion/edit/:id', element: <CreateSalesPromotion /> },
        { path: '/scandata', element: <Navigate to={`/scandata/${stores[0]?.id}`} /> },
        { path: '/scandata/:id', element: <ScanDataDashboard /> },
        { path: '/scandata/:id/sales', element: <ScanDataSalesSubmit /> },
        { path: '/scandata/:id/setup', element: <ScanDataSetup /> },
        { path: '/scandata/:id/items', element: <ScanDataSalesItems /> },
      ],
    },

    {
      path: '/',
      children: [
        {
          path: 'login',
          element: authenticated && !hasURLToken ? <Navigate to="/" replace /> : <Login />,
        },
        { path: 'not-found', element: <NotFound /> },
        { path: 'page-not-found', element: <NotFound /> },
        {
          path: '*',
          element: authenticated ? <Navigate to="/page-not-found" replace /> : <Navigate to="/login" replace />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/page-not-found" replace />,
    },
  ]);

  return routes;
}
