import { DELETE_EVENT, FETCH_EVENTS_FAILURE, FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS } from './type';

import { getEventListApi, getSalesAndPromotionListApi } from 'src/services/EventServices';
import { toast } from 'react-hot-toast';
import { message } from 'src/assets/constant/messages';

export const fetchEventsRequest = () => {
  return {
    type: FETCH_EVENTS_REQUEST,
  };
};

export const fetchEventsSuccess = (events, totalpage, count) => {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: { events, totalpage, count },
  };
};

export const fetchEventsFailure = (error) => {
  return {
    type: FETCH_EVENTS_FAILURE,
    payload: error,
  };
};

export const fetchEventListthunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchEventsRequest());
      const { data } = await getSalesAndPromotionListApi(params);
      if (data?.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(fetchEventsSuccess(data?.data?.salesPromotions, data?.data?.count / params.limit, data?.data?.count));

      onSuccess();
    } catch (error) {
      dispatch(fetchEventsFailure(error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const deleteItem = (id) => {
  return {
    type: DELETE_EVENT,
    payload: { id },
  };
};
