import { FETCH_SALES_SUBMIT_FAILURE, FETCH_SALES_SUBMIT_REQUEST, FETCH_SALES_SUBMIT_SUCCESS } from './type';
import { getSalesSubmitListApi } from 'src/services/SalesSubmit.Services';

export const fetchSalesSubmitRequest = () => {
  return {
    type: FETCH_SALES_SUBMIT_REQUEST,
  };
};

export const fetchSalesSubmitSuccess = (salesSubmits, totalpage, timezone) => {
  return {
    type: FETCH_SALES_SUBMIT_SUCCESS,
    payload: { salesSubmits, totalpage, timezone },
  };
};

export const fetchSalesFailure = (error) => {
  return {
    type: FETCH_SALES_SUBMIT_FAILURE,
    payload: error,
  };
};

export const fetchSalesSubmitListthunkAction = (id, params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchSalesSubmitRequest());
      const { data } = await getSalesSubmitListApi(id, params);
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(
        fetchSalesSubmitSuccess(data?.data?.salesList, data?.data?.count / params.limit, data?.data?.timeZoneOffset)
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchSalesFailure(error.message));
      onError(error.response?.data?.message || error.message);
    }
  };
};
