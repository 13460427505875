import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Zoom from '@mui/material/Zoom';
import './modal.css';

const style = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  transform: 'translate(-50%, -50%)',
  width: window.innerWidth - 50,
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
  overflowY: 'none',
  borderRadius: '8px',
  maxWidth: '500px',
  maxHeight: 'fit-content',
};

export default function CustomPopUp({ title, subtitle, open, handleClose, children }) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open} style={{ transformOrigin: 'center' }}>
          <Box sx={style}>
            <div style={{ marginBottom: '30px' }}>
              <span style={{ fontSize: '24px', fontWeight: '500', marginBottom: '15px' }}>{title}</span>
              <br />
              <span style={{ color: '#5c5a5a' }}>{subtitle}</span>
            </div>
            {children}
          </Box>
        </Zoom>
      </Modal>
    </div>
  );
}
